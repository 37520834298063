import { buildStateRequestStatus, buildBasicState, buildStateListResult } from '@/helpers/store';
import requestStatusKeys from './_requestStatusKeys';
import basicKeys from './_basicKeys';

export default {
    ...buildBasicState(basicKeys),
    ...buildStateRequestStatus(requestStatusKeys),
    ...buildStateListResult(['usersListResult']),
    ...buildStateListResult(['pendingUsersListResult']),
    selectedProduct: null,
};


