export const headers = [
    {
        text: 'Cliente',
        sortable: true,
        value: 'name',
        width: 400,
        align: 'left',
    },
    {
        text: 'Raiz/CPF',
        sortable: false,
        value: 'raiz',
        width: 144,
        align: 'center',
    },
    {
        text: 'ID Solicitação',
        sortable: false,
        value: 'id',
        width: 112,
        align: 'center',
    },
    {
        text: 'Data da Solicitação',
        sortable: false,
        value: 'createdAt',
        width: 144,
        align: 'center',
    },
    {
        text: 'Status',
        sortable: false,
        value: 'status',
        align: 'center',
    },
    {
        text: 'Produto',
        sortable: false,
        value: 'product',
        width: 160,
        align: 'center',
    },
    {
        text: 'Gerente',
        sortable: false,
        value: 'manager',
        width: 320,
        align: 'center',
    },
    {
        text: 'Garantias',
        sortable: false,
        value: 'guarantee',
        width: 80,
        align: 'center',
    },
    {
        text: 'UF',
        sortable: false,
        value: 'state',
        width: 80,
        align: 'center',
    },
    {
        text: 'Aprovado',
        sortable: false,
        value: 'approvedAmount',
        align: 'center',
    },
    {
        text: 'Utilizado',
        sortable: false,
        value: 'inUseAmount',
        align: 'center',
    },
    {
        text: 'Movimento',
        sortable: false,
        value: 'movementAmount',
        align: 'center',
    },
    {
        text: 'Disponível',
        sortable: false,
        value: 'availableAmount',
        align: 'center',
    },
    {
        text: 'Decisão',
        sortable: false,
        value: 'decisionDate',
        align: 'center',
    },
    {
        text: 'Validade',
        sortable: false,
        value: 'validityDate',
        align: 'center',
    },
    {
        text: 'Atualizado',
        sortable: false,
        value: 'updatedAt',
        align: 'center',
    },
];