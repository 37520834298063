<template>
    <v-dialog persistent content-class="modal-default modal-default-x-small" v-model="showModal">
        <header class="header">
            {{ $tc('origination.smart.dialog.title') }}
            <v-icon role="button" title="Fechar" @click="$router.back()" color="altPrimary">
                mdi-close-thick
            </v-icon>
        </header>

        <v-main>
            <section>
                <v-form
                    :lazy-validation="true"
                    v-model="valid"
                    class="pa-0 mt-9 form-custom-disabled"
                >
                    <h2 class="text-h6 mb-3 primary--text">Simulado</h2>
                    <v-row>
                        <v-col lg="6">
                            <v-card
                                class="pa-4 d-flex flex-column text-center flat-container"
                                max-width="150"
                                elevation="0"
                                :style="getCardColor('Atual')"
                            >
                                <span>Atual</span>
                            </v-card>
                        </v-col>
                        <v-col lg="6">
                            <v-card
                                class="pa-4 d-flex flex-column text-center flat-container"
                                max-width="150"
                                :style="getCardColor('Simulado')"
                                elevation="0"
                            >
                                <span>Simulado</span>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col sm="12">
                            <v-menu
                                ref="menuEmissao"
                                v-model="menuEmissao"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                :return-value.sync="form.startAt"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        class="v-input-default"
                                        color="primary"
                                        v-model="form.startAt"
                                        :rules="[rules.required]"
                                        label="Início relacionamento *"
                                        prepend-icon="event"
                                        readonly
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-if="menuEmissao"
                                    v-model="date"
                                    no-title
                                    scrollable
                                    :min="minDate"
                                    locale="pt-br"
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn color="secondary" depressed @click="menuEmissao = false"
                                        >Cancelar</v-btn
                                    >
                                    <v-btn
                                        color="secondary"
                                        depressed
                                        @click="$refs.menuEmissao.save(form.startAt)"
                                    >
                                        {{ $tc('defaults.form.save') }}
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-start">
                            <span class="primary--text">
                                {{ $tc('origination.smart.dialog.disclaimer') }}
                            </span>
                        </v-col>
                        <v-col sm="12">
                            <v-text-field
                                color="primary"
                                v-model="form.value"
                                label="Valor"
                                :rules="[rules.required]"
                                v-mask="currencyMask"
                                class="v-input-default"
                                :validate-on-blur="true"
                            ></v-text-field>
                        </v-col>

                        <v-col xs="12" lg="12" class="d-flex justify-end">
                            <v-btn
                                depressed
                                class="mt-4 mb-4 white--text"
                                @click="onSubmit"
                                color="secondary"
                            >
                                <span class="white--text text-none">
                                    {{ $tc('defaults.form.continue') }}
                                </span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </section>
        </v-main>
        <Loader v-if="selecaoManualRequestStatus === RequestStatusEnum.START" mode="overlay" />
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { currency as currencyMask, currencyUnmask } from '@/helpers/masks';
import { formatarDataBarra } from '@/helpers/data';
import Loader from '@/components/base/Loader';
import { RequestStatusEnum } from '@/helpers/consts';
import { notification } from '@/helpers/notification';
export default {
    components: {
        Loader,
    },
    props: {
        modal: {
            type: Boolean,
            default: false,
        },
        config: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        showModal: false,
        menuEmissao: false,
        form: {},
        currencyMask,
        valid: false,
        formatarDataBarra,
        RequestStatusEnum,
        date: new Date().toISOString().split('T')[0],
        minDate: new Date().toISOString().split('T')[0],
        rules: {
            required: value => !!value || 'Campo obrigatório',
        },
    }),
    watch: {
        date(newValue) {
            this.form.startAt = this.formatarDataBarra(newValue);
        },
        selecaoManualRequestStatus(newValue) {
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    this.$t('origination.smart.dialog.error', { msg: newValue.message })
                );
                return RequestStatusEnum.ERROR;
            }
        },
    },
    computed: {
        ...mapGetters('originacao', {
            selectedProduct: 'selectedProduct',
            selecaoManualRequestStatus: 'selecaoManualRequestStatus',
        }),
    },
    methods: {
        ...mapActions('originacao', {
            selecaoSmart: 'selecaoSmart',
        }),
        closeModal() {
            this.showModal = false;
            this.$emit('onClose');
        },
        getCardColor(key) {
            const date = new Date().toISOString().split('T')[0];
            if (key === 'Atual') {
                return this.date === date ? 'background: #DDF2EF' : 'background: #FFF';
            }
            return this.date !== date ? 'background: #DDF2EF' : 'background: #FFF';
        },
        onSubmit() {
            const payload = {
                id_produto: this.selectedProduct.id,
                tipoSelecao: this.config.type,
                tipoMoeda: this.config.currency,
                tipoOperacao: this.config.operation,
                valorTeto: currencyUnmask(this.form.value),
                data: this.date,
            };
            this.selecaoSmart(payload);
        },
    },
    created() {
        this.form.startAt = this.formatarDataBarra(this.date);
        this.showModal = this.modal;
    },
};
</script>
<style scoped>
header.header {
    z-index: 1;
}
</style>
