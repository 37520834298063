const OriginacaoRevisao = () => import('@/mfes/originacao/views/Revisao');
const OriginacaoSelecao = () => import('@/mfes/originacao/views/Selecao');

export default [
    {
        path: 'nova/selecao',
        name: 'Originação - Seleção',
        component: OriginacaoSelecao,
        meta: { title: 'Nova Originação', config: { operation: 3}, type: 'origination' }
    },
    {
        path: 'nova/revisao/:id',
        name: 'Originação - Revisão',
        component: OriginacaoRevisao,
        meta: { title: 'Revisão de Originação', type: 'origination' }
    },
    {
        path: 'factoring/selecao',
        name: 'Factoring - Seleção',
        component: OriginacaoSelecao,
        meta: { title: 'Novo Factoring', config: { type:  0, currency: 0, operation: 3}, type: 'factoring' }
    },
    {
        path: 'factoring/revisao/:id',
        name: 'Factoring - Revisão',
        component: OriginacaoRevisao,
        meta: { title: 'Revisão de Factoring', type: 'factoring' }
    },
];
