<template>
    <v-main v-if="limit">
        <div class="pt-6 pb-6 pr-6 pl-6 ">
            <v-row class="header-container">
                <v-col md="9" sm="4" align-self="center">
                    <v-icon class="icon-account mr-3 icon-card-title">mdi-account-outline</v-icon>
                    <span class="pt-5 pb-5 title-name">{{ limit.name }}</span>
                </v-col>
                <v-col md="3" sm="8" align="right">
                    <v-btn
                        color="secondary"
                        depressed
                        title="Solicitar Individual"
                        :to="`/admin/credito/limites/solicitar/${limit.document}/valor`"
                    >
                        <v-icon class="mr-2">mdi-credit-card-plus-outline</v-icon>
                        Solicitar Individual
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <div class="pb-2 pr-9 pl-9 pt-7 pb-main-default">
            <v-row>
                <v-col md="9" sm="4" align-self="center">
                    <v-icon class="mr-1 icon-card icon-card-title">mdi-credit-card-outline</v-icon>
                    <span class="pt-5 pb-5 limite-title">Utilização do Limite</span>
                </v-col>
                <v-col md="3" sm="3" class="mt-3" align-self="center" align="right">
                    <img class="widgetImg2" height="38" :src="imgUrl" />
                </v-col>
            </v-row>
            <v-row>
                <v-col lg="12" xl="12" cols="12" class="mt-0 pb-0 pt-0">
                    <div id="wrapper">
                        <div id="chart-area">
                            <apexchart
                                height="350px"
                                :series="[limit.percentage]"
                                :options="options"
                            ></apexchart>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-row justify="center" class="mt-0">
                <span class="label-valor font-weight-light">Valor disponível</span>
            </v-row>
            <v-row justify="center">
                <sup class="symbol-value">R$</sup>
                <span class="text-value">{{ formatWithoutSymbol(limit.availableAmount) }}</span>
            </v-row>
            <v-row justify="center">
                <v-col md="8">
                    <v-divider></v-divider>
                </v-col>
            </v-row>
            <v-row justify="center" class="pb-6">
                <v-col md="4" sm="12" class="pa-0 date-label" align="center">
                    <v-icon class="mr-1 mb-1 icon-card">mdi-calendar-check-outline</v-icon>
                    <span>Data Decisão: </span>
                    <span class="font-weight-light">{{ formatarData(limit.decisionDate) }}</span>
                </v-col>
                <v-col md="4" sm="12" class="pa-0 date-label" align="center">
                    <v-icon class="mr-1 mb-1 icon-card">mdi-calendar-check-outline</v-icon>
                    <span>Validade Decisão: </span>
                    <span class="font-weight-light">{{ formatarData(limit.validityDate) }}</span>
                </v-col>
            </v-row>

            <v-row class="pb-6">
                <v-col class="mt-2" lg="4" sm="12">
                    <CardDetail
                        :amount="formatWithoutSymbol(limit.value)"
                        :date="formatarData(limit.updatedAt)"
                        :title="limit.status === 4 ? 'Solicitação' : 'Aprovado'"
                    />
                </v-col>

                <v-col class="mt-2" lg="4" sm="12">
                    <CardDetail
                        :amount="formatWithoutSymbol(limit.inUseAmount)"
                        :date="formatarData(limit.updatedAt)"
                        :title="'Utilizado'"
                    />
                </v-col>

                <v-col class="mt-2" lg="4" sm="12">
                    <CardDetail
                        :amount="formatWithoutSymbol(limit.movementAmount)"
                        :date="formatarData(limit.updatedAt)"
                        :title="'Movimento'"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="9" class="mb-0 pb-0" align-self="center">
                    <v-icon class="mr-3 icon-card icon-card-title">mdi-book-open-outline</v-icon>
                    <span class="title-icon">Dados</span>
                </v-col>
                <v-col cols="12">
                    <v-divider></v-divider>
                </v-col>
            </v-row>

            <div>
                <v-row class="ml-2 mt-5">
                    <div class="list-data">
                        <span class="font-weight-bold">Nº Documento: </span>
                        <span class="font-weight-light">{{ limit.document }}</span>
                    </div>
                </v-row>
                <v-row class="ml-2 mt-5">
                    <div class="list-data">
                        <span class="font-weight-bold">CNPJs da Raiz: </span>
                        <span class="font-weight-light">{{ limit.raiz }}</span>
                    </div>
                </v-row>
                <v-row class="ml-2 mt-5" v-if="limit.groupLimit">
                    <div class="list-data">
                        <span class="font-weight-bold">Grupo: </span>

                        <router-link
                            class="text-decoration-none"
                            :to="`/admin/credito/limites/${limit.groupLimit.id}/grupo`"
                            :title="'Grupo ' + limit.groupData.name"
                        >
                            <span class="font-weight-light">
                                {{ limit.groupData.name }}
                                <v-icon size="dense">mdi-open-in-new</v-icon>
                            </span>
                        </router-link>
                    </div>
                </v-row>
                <v-row class="ml-2 mt-5">
                    <div class="list-data">
                        <span class="font-weight-bold">Localidade: </span>
                        <span class="font-weight-light"
                            >{{ limit.location }} - {{ limit.city }}/{{ limit.state }}</span
                        >
                    </div>
                </v-row>
                <v-row class="ml-2 mt-5" v-if="limit.comment">
                    <div class="list-data">
                        <span class="font-weight-bold">Comentários: </span>
                        <span class="font-weight-light">{{ limit.comment }}</span>
                    </div>
                </v-row>
                <v-row class="mt-5">
                    <v-col cols="9" align-self="center">
                        <v-icon class="mr-3 icon-card icon-card-title"
                            >mdi-book-open-outline</v-icon
                        >
                        <span class="title-icon">Solicitações de limites</span>
                    </v-col>
                </v-row>
                <v-row class="mb-3">
                    <v-divider></v-divider>
                </v-row>
            </div>

            <div class="pt-10 pb-6">
                <v-data-table
                    :headers="headers"
                    :items="limitsFromClientListResult.items"
                    hide-default-footer
                    :loading-text="'Carregando limites'"
                    class="elevation-0 pb-4 flat-container"
                    id="table-meus-clients-consulta"
                >
                    <template v-slot:[`item.status`]="{ item }">
                        <v-chip small :class="`status-chip ${getCredito(item.status).color}`">
                            {{ getCredito(item.status).label }}
                        </v-chip>
                    </template>
                    <template slot="no-data">
                        <div>Não há dados</div>
                    </template>
                    <template v-slot:footer>
                        <v-pagination
                            color="secondary"
                            v-model="page"
                            class="mt-3"
                            :length="limitsFromClientListResult.totalPages"
                            :total-visible="7"
                        ></v-pagination>
                    </template>
                </v-data-table>

                <Loader
                    align="center"
                    justify="center"
                    class="text-center mt-3"
                    v-if="limitsFromClientListRequestStatus === RequestStatusEnum.START"
                />
            </div>
        </div>
    </v-main>
    <Loader align="center" justify="center" class="text-center mt-12" v-else />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getImgUrl } from '@/helpers/images';
import { CreditStatusColors, RequestStatusEnum } from '@/helpers/consts';
import { notification } from '@/helpers/notification';
import { headers } from './_headersDetalhe';
import chartOptions from './_chartOptions';
import CardDetail from '@/mfes/credito/components/_cardDetail';
import { formatarData } from '@/helpers/data';
import { formatWithoutSymbol } from '@/helpers/formatarDinheiro';
import Loader from '@/components/base/Loader';

export default {
    components: {
        CardDetail,
        Loader,
    },
    data: () => ({
        headers,
        options: chartOptions,
        RequestStatusEnum,
        page: 1,
    }),
    computed: {
        ...mapGetters('credito', {
            limit: 'limit',
            limitRequestStatus: 'limitRequestStatus',
            limitsFromClientListResult: 'limitsFromClientListResult',
            limitsFromClientListRequestStatus: 'limitsFromClientListRequestStatus',
            selectedProduct: 'selectedProduct',
        }),
        id() {
            return this.$router.history.current.params.id;
        },
        imgUrl() {
            return this.limit && this.limit.img ? getImgUrl(this.limit.img) : '';
        },
    },
    methods: {
        ...mapActions('credito', {
            getLimit: 'getLimit',
            resetLimit: 'resetLimit',
            fetchLimitsFromClient: 'fetchLimitsFromClient',
            resetLimitsFromClient: 'resetLimitsFromClient',
        }),
        getCredito(key) {
            return CreditStatusColors[parseInt(key, 10)];
        },
        async searchLimits() {
            if (!this.limit) {
                return;
            }
            const filters = {
                nomeOuDoc: this.limit.document,
                page: this.page - 1,
                limit: 10,
                produto: this.selectedProduct.id,
            };
            const payload = {
                id_produto: this.selectedProduct.id,
                tipo: 1,
                filters,
            };
            await this.fetchLimitsFromClient(payload);
        },
        formatarData,
        formatWithoutSymbol,
    },
    async created() {
        this.resetLimit();
        this.resetLimitsFromClient();
        this.getLimit({ id_produto: this.selectedProduct.id, id: this.id });
    },
    watch: {
        limit() {
            this.searchLimits();
        },
        limitRequestStatus(newValue) {
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    'Ocorreu um erro ao requisitar o limite: ' + newValue.message
                );
                return RequestStatusEnum.ERROR;
            }
        },
        page() {
            this.searchLimits();
        },
    },
};
</script>

<style scoped lang="scss">
@import './Consulta.scss';

.icon-card-title.v-icon {
    vertical-align: text-bottom;
}
</style>
