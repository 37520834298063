export default {
    'pt-br': {
        gestao: {
            titulo: 'títulos | título | títulos',
            askRemove:
                ' | Deseja realmente remover o título? | Deseja realmente remover os {count} títulos?',
            askRemoveFactoring: 'Deseja realmente remover o(s) cliente(s) e nota(s)?',
            tabs: {
                titulos: 'Títulos Formalizados',
                followUp: 'Acompanhamento de títulos',
                outgoes: 'Desembolsos',
            },
        },
        outgo: {
            label: 'desembolsos',
            askSend: 'Deseja realmente enviar o desembolso?',
            askCancel: 'Deseja realmente cancelar o desembolso?',
            eligible: {
                label: 'elegíveis | elegível | elegíveis',
            },
            ineligible: {
                label: 'inelegíveis | inelegível | inelegíveis',
            },
            new: 'Novo desembolso',
            request: 'Solicitação de desembolso',
            askForNew: {
                start: 'Enviado pedido de novo dembolso',
                success: 'Pedido de novo dembolso solicitado com sucesso',
                error: 'Ocorreu um erro ao solicitar novo desembolso: {msg}',
            },
            exporttitles: {
                error: 'Ocorreu um erro ao fazer a exportação: {msg}',
                success: 'Arquivo exportado com sucesso: {msg}',
            },
            pending: {
                label: 'Em elegibilidade',
                warning: 'Ao concluir você será notificado por e-mail',
            },
            send: {
                label: 'Enviar desembolso',
                success: 'Desembolso enviado com sucesso',
                error: 'Ocorreu um erro ao enviar o desembolso: {msg}',
            },
            cancel: {
                label: 'Cancelar desembolso',
                success: 'Desembolso cancelado com sucesso',
                error: 'Ocorreu um erro ao cancelar o desembolso: {msg}',
            },
        },
        delivery: {
            label: 'remessas',
            removeNFsByIdAndClient: {
                success: 'Cliente(s)/NF(s) removidos com sucesso',
                error: 'Ocorreu um erro ao remover cliente(s)/nf(s) do desembolso: {msg}',
            },
        },
        origination: {
            selectedNFs:
                'Nenhuma nota selecionada | 1 nota selecionada | {count} notas selecionadas',
            revision: {
                warning: `O desembolso referente a formalização deste títulos estarão condicionados a verficação de disponibilidade de recursos do fundo,
            bem como a verificação dos critérios de elegibilidade e limites de
            crédito.`,
                confirm: 'Confirmar Remessa',
                removeNFsByIdAndClientRequestStatus: {
                    error: 'Ocorreu um erro ao concluir a originação: {msg}',
                },
                exportOriginacaoRequestStatus: {
                    error: 'Ocorreu um erro ao fazer a exportação: {msg}',
                },
            },
            dialog: {
                confirmLeave:
                    'Ao sair, a seleção será perdida e uma nova originação será iniciada.',
            },
            simulation: {
                resetToDefault: 'Reiniciar',
                helper: 'Escolha uma data no gráfico para simular o cenário',
            },
            selection: {
                finish: 'Finalizar Seleção',
                new: 'Nova Originação',
            },
            smart: {
                dialog: {
                    title: 'Seleção Smart',
                    error: 'Ocorreu um erro ao criar a Seleção Smart: {msg}',
                    disclaimer:
                        'A ferramenta de Seleção Smart vai selecionar Notas Fiscais de forma automática e otimizada limitando-se a este valor:',
                },
            },
        },
        factoring: {
            revision: {
                warning: `O desembolso das NFs da remessa estará condicionado a verificação de disponibilidade de recursos do fundo, bem como a verificação dos critérios de elegibilidade e limites de crédito.`,
                confirm: 'Confirmar Remessa',
            },
            dialog: {
                confirmLeave: 'Ao sair, a seleção será perdida e um novo factoring será iniciado.',
            },
        },
        cadastros: {
            manufacturer: {
                update: {
                    success: 'Dados do podutor atualizados sucesso',
                    error: 'Ocorreu um erro ao atualizar os dados do produtor: {msg}',
                },
            },
            accessProfile: {
                new: 'Novo perfil',
            },
            users: {
                list: {
                    error: 'Ocorreu um erro ao listar os usuários: {msg}',
                },
                update: {
                    success: 'Atualizado com sucesso',
                    error: 'Ocorreu um erro ao atualizar: {msg}'
                },
                create: {
                    success: 'Atualizado com sucesso',
                    error: 'Ocorreu um erro ao cadastrar: {msg}'
                },
                askForNewToken: {
                    success: 'Novo link enviado com sucesso',
                    error: 'Ocorreu um erro ao requisitar um novo link: {msg}'
                }
            }
        },
        credito: {
            request: {
                extraComments: 'Comentários adicionais',
            },
            batch: {
                buttonLabel: 'Não possui a planilha modelo? Faça download dela',
                disclaimer: '*Preencher todos os campos da planilha',
            },
        },
        defaults: {
            form: {
                previous: 'Anterior',
                next: 'Próximo',
                aware: 'Estou ciente',
                confirm: 'Confirmar',
                redo: 'Refazer',
                close: 'Fechar',
                reload: 'Recarregar',
                cancel: 'Cancelar',
                save: 'Salvar',
                continue: 'Continuar',
            },
            tabs: {
                consult: 'Consultar',
                pending: 'Pendências',
                general: 'Informações Gerais',
                addresses: 'Endereço',
                config: 'Configurações',
                password: 'Senha',
            },
            dialog: {
                leave: 'Deseja realmente sair?',
            },
            days: '0 dias | 1 dia | {count} dias',
        },
        dashboards: {
            error: 'Ocorreu um erro ao solicitar carregar as informações do gráfico.',
            tableError: 'Ocorreu um erro ao solicitar carregar as informações da tabela.',
            balancePerUF: {
                label: 'Concentração de Saldo A Vencer Por UF',
                detailed: 'Visão Detalhada',
                modal: {
                    title: 'Concentração de estado por UF',
                },
            },
            limitsUsagePerUF: {
                label: 'Utilização de Limites por UF',
            },
            fundWallet: {
                label: 'Carteira de Direitos Creditórios',
            },
            averageClientExposure: {
                title: 'Exposição',
                subtitle: 'Média por cliente',
            },
            clientsCount: {
                title: 'Clientes',
                subtitle: 'Quantidade',
            },
            delayGrowLastDays: {
                title: 'Indicadores de Atraso',
            },
            limitsPerUF: {
                title: 'Utilização de Limites por UF',
                detailed: 'Visão Detalhada',
            },
            top10Exposure: {
                title: 'Exposição',
                subtitle: 'Top 10',
            },
            availableCash: {
                title: 'Caixa',
                subtitle: 'Disponível',
            },
            overdueBalance: {
                title: 'Saldo',
                subtitle: 'Em atraso',
            },
            mainHighlight: {
                title: 'Carteira do Fundo',
                subtitle: 'Saldo a vencer',
            },
            analyticsLists: {
                label: 'Listas Analíticas',
                tabs: {
                    topClientsExposure40: '40 clientes com maior exposição',
                    overdueClients: 'Clientes em Atraso',
                },
            },
        },
    },
};
