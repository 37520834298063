import axios from '@/axios';

export const getClientData = id => axios.get('/v2/cadastro/cliente/' + id);

export const uploadExcel = (idProduct, formData) =>
    axios.post(`/v2/${idProduct}/excel/solicitacoes`, formData);

export const listLimits = (idProduct, tipo, filters) =>
    axios.get(`/v2/${idProduct}/limite/tipo/paginacao/${tipo}?${filters}`);

export const requestPerValue = (idProduct, value, payload) =>
    axios.patch(`/v2/${idProduct}/limite/valorsolicitacao/${value}`, payload);

export const createRegister = (id, body) => axios.post(`/v2/${id}/cadastro/cliente`, body);

export const getLimit = (idProduct, id) => axios.get(`/v2/${idProduct}/limite/${id}`);
export const getStatusList = idProduct => axios.get(`/v2/${idProduct}/limite/status`);
export const request = (id, body) => axios.post(`/v2/${id}/limite/solicitacao`, body);
export const perRoot = (idProduct, identifier) =>
    axios.get(`/v2/${idProduct}/limite/raiz/${identifier}`);

export const managers = id => axios.get(`/v2/cadastro/tipo/${id}`);

export const listSegments = id => axios.get(`/v2/cadastro/produtos/${id}/segmentos`);

export const findRegistersByDocument = key => axios.get(`/v2/cadastro/documento/${key}`);

export const listStates = () => axios.get(`/v2/enums/estados`);

export const listLimitsDownload = (idProduct, tipo, filters) =>
    axios.get(`/v2/${idProduct}/limite/download/${tipo}?${filters}`, {
        responseType: 'blob',
    });
