<template>
    <v-main>
        <h3 class="mb-4 mt-3">
            <v-icon>mdi-account-circle-outline</v-icon>
            {{ user.name }}
        </h3>
        <div class="pt-9 pr-9 pl-9 pb-main-default">
            <v-container>
                <v-form class="form-custom-disabled">
                    <v-row>
                        <v-col md="8">
                            <v-text-field
                                color="primary"
                                v-model="user.name"
                                label="Nome Completo"
                                class="v-input-default"
                            ></v-text-field>
                        </v-col>
                        <v-col md="4">
                            <v-text-field
                                color="primary"
                                v-model="user.cpfCnpj"
                                label="CPF/CNPJ"
                                class="v-input-default"
                            ></v-text-field>
                        </v-col>
                        <v-col md="4">
                            <v-text-field
                                color="primary"
                                v-model="user.email"
                                label="E-mail"
                                readonly
                                class="v-input-default"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12" md="3">
                            <label>Status</label>
                            <v-radio-group v-model="user.active" row>
                                <v-radio
                                    v-for="item in itemsStatus"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <label>Dados de Responsável</label>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="5">
                            <v-text-field
                                color="primary"
                                v-model="user.email"
                                label="E-mail"
                                class="v-input-default"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-btn @click="onSubmit" color="secondary" class="mt-6">
                        Alterar
                    </v-btn>
                </v-form>
            </v-container>
        </div>
    </v-main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
//import { email as emailValidator } from '@/helpers/validators';
import { RequestStatusEnum } from '@/helpers/consts';
import { notification } from '@/helpers/notification';

export default {
    data: () => ({
        dataUser: {},
        itemsStatus: [
            {
                label: 'Ativo',
                value: true,
            },
            {
                label: 'Inativo',
                value: false,
            },
        ],
    }),
    watch: {
        updateUserRequestStatus(newValue) {
            if (newValue === RequestStatusEnum.SUCCESS) {
                notification(
                    this.$dialog.notify.success,
                    this.$tc('cadastros.users.update.success')
                );
                this.$router.push(`/admin/cadastros/usuarios`);
                return RequestStatusEnum.SUCCESS;
            }
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    this.$t('cadastros.users.update.error', { msg: newValue.message })
                );
                return RequestStatusEnum.ERROR;
            }
        },
    },
    computed: {
        ...mapGetters('cadastros', {
            updateUserRequestStatus: 'updateUserRequestStatus',
            user: 'userDetail',
        }),
        queryId() {
            return this.$route.params.id;
        },
    },
    methods: {
        ...mapActions('cadastros', {
            fetchUser: 'fetchUser',
            updateUser: 'updateUser',
        }),
        onSubmit() {
            this.updateUser(this.user);
        },
    },
    mounted() {
        this.fetchUser({ id: this.queryId });
    },
};
</script>
