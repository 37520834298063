import { gettersBuilder } from '@/helpers/store';
import requestStatusKeys from './_requestStatusKeys';
import basicKeys from './_basicKeys';

export default {
    pendingRegistersResult: state => ({
        items: state.pendingRegisters,
        totalPages: state.pendingRegistersTotalPages,
    }),
    statesEnumAsArray: state => {
        if (!state.states) {
            return [];
        }
        return Object.values({ ...state.states }).sort((prev, next) => {
            return prev > next ? 1 : -1;
        });
    },
    usersListResult: state => ({ ...state.usersListResult }),
    pendingUsersListResult: state => ({ ...state.pendingUsersListResult }),
    ...gettersBuilder(basicKeys),
    ...gettersBuilder(requestStatusKeys),
    selectedProduct: state => state.selectedProduct,
};
