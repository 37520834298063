export default [
    'pendingRegisters',
    'pendingRegistersTotalPages',
    'fileTypesList',
    'pendingDocumentsList',
    'states',
    'exportManufacturers',
    'manufacturersList',
    'manufacturersListTotalPages',
    'userList',
    'segmentsList',
    'resaleList',
    'exportManufacturers',
    'manufacturerDetail',
    'userDetail',
];