var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"md":"6","sm":"12","cols":"12","lg":"6"}},[_c('MainFilter',{attrs:{"hasExtraFilters":false},on:{"onInputChange":_vm.filterInputChanged,"onClick":_vm.showFilters}})],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.filter),expression:"filter"}],staticClass:"mb-9"},[_c('Filters',{attrs:{"loadFilters":false},on:{"onApply":_vm.searchListener}})],1),_c('Table',{staticClass:"mt-6",attrs:{"items":_vm.items,"pages":_vm.totalPages,"currentPage":_vm.page,"headers":_vm.headers,"requestStatus":_vm.pendingRegistersRequestStatus},on:{"onPager":_vm.onPager,"onDocumentBtn":_vm.onDocumentBtn}}),(_vm.pendingRegistersRequestStatus === _vm.RequestStatusEnum.START)?_c('Loader',{staticClass:"mt-9",attrs:{"align":"center"}}):_vm._e(),_c('v-dialog',{attrs:{"content-class":"modal-default"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('header',[_vm._v(" Enviar documentos "),_c('v-icon',{attrs:{"role":"button","title":"Fechar","color":"altPrimary"},on:{"click":_vm.closeModal}},[_vm._v("mdi-close-thick")])],1),_c('v-main',[_c('section',[_c('v-form',[(_vm.filesRequiredToUpload.length > 0)?_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(
                            _vm.uploadPendingDocumentsRequestStatus === _vm.RequestStatusEnum.IDLE &&
                            _vm.pendingDocumentsListRegistersRequestStatus ===
                                _vm.RequestStatusEnum.SUCCESS
                        ),expression:"\n                            uploadPendingDocumentsRequestStatus === RequestStatusEnum.IDLE &&\n                            pendingDocumentsListRegistersRequestStatus ===\n                                RequestStatusEnum.SUCCESS\n                        "}],staticClass:"mt-9"},_vm._l((_vm.filesRequiredToUpload),function(file){return _c('v-col',{key:file.id,attrs:{"cols":"12","sm":"12","md":"6"}},[_c('h4',[_vm._v(_vm._s(file.descricao))]),_c('v-main',{staticClass:"mt-3"},[_c('FilePicker',{ref:"filePicker",refInFor:true,attrs:{"acceptedFileTypes":'image/*'},on:{"onReset":function($event){return _vm.resetted(file.id)},"onFileChange":function (_file) { return _vm.fileChanged(_file, file.id); }}})],1)],1)}),1):_vm._e(),(
                            _vm.uploadPendingDocumentsRequestStatus === _vm.RequestStatusEnum.START ||
                            _vm.pendingDocumentsListRegistersRequestStatus ===
                                _vm.RequestStatusEnum.START
                        )?_c('Loader',{staticClass:"mt-9",attrs:{"align":"center"}}):_vm._e(),_c('footer',{staticClass:"mt-6",attrs:{"align":"right"}},[_c('v-btn',{attrs:{"disabled":!_vm.isValid ||
                                _vm.uploadPendingDocumentsRequestStatus === _vm.RequestStatusEnum.START,"color":"secondary","depressed":""},on:{"click":_vm.uploadFiles}},[_vm._v(" "+_vm._s(_vm.$tc('defaults.form.confirm'))+" ")])],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }