import simpleBuilder from '@/helpers/dtos/simpleBuilder';
const keys = {
    vencimentoAntecipadoIdProduto: 'productId',
    vencimentoAntecipadoValorAntecipado: 'value',
    vencimentoAntecipadoValorAvencer: 'dueValue',
    vencimentoAntecipadoValorVencido: 'overdueValue',
    vencimentoAntecipadoValorPago: 'paidValue',
    vencimentoAntecipadoData: 'date',
    vencimentoAntecipadoDataLeitura: 'readDate',
    vencimentoAntecipadoPl: 'pl',
};
export default simpleBuilder(keys);
