import { RequestStatusEnum, newErrorBuilder } from '@/helpers/consts';
import {
    uploadPendingDocuments,
    listRegisters,
    fileTypesList,
    listStates,
    users,
    listSegments,
} from '../service';
import cadastroRegisterFromAPI from '@/helpers/dtos/cadastroRegisterFromAPI';
import queryString from '@/helpers/dtos/queryString';
import { _fetchDefaultParserPagination } from '@/helpers/store';
import managerFromAPI from '@/helpers/dtos/managerFromAPI';

import actionsManufacturers from './actions.manufacturers';
import actionsUsers from './actions.users';

export default {
    async fetchPendingRegisters({ commit }, { idProduct, filters }) {
        commit('setPendingRegistersRequestStatus', RequestStatusEnum.START);
        try {
            const params = queryString(filters, {});
            const { data } = await listRegisters(idProduct, params);
            _fetchDefaultParserPagination(
                commit,
                data,
                cadastroRegisterFromAPI,
                'PendingRegisters'
            );
        } catch (error) {
            commit('setPendingRegistersRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchPendingDocuments({ commit }, { idProduct, filters }) {
        commit('setPendingDocumentsListRegistersRequestStatus', RequestStatusEnum.START);
        try {
            const params = queryString(filters, {});
            const { data } = await listRegisters(idProduct, params);
            //fluxo para remover idTipoArquivo repetidos
            let result = [];
            let idTipoArquivos = [];
            data.data.content
                .filter(item => item.idStatus !== '3')
                .map(item => {
                    const idTipoArquivo = parseInt(item.idTipoArquivo);
                    if (idTipoArquivos.indexOf(idTipoArquivo) < 0) {
                        result.push({
                            idTipoArquivo: idTipoArquivo,
                            idSolicitacao: item.id,
                        });
                        idTipoArquivos.push(idTipoArquivo);
                    }
                });
            commit('setPendingDocumentsList', result);
            commit('setPendingDocumentsListRegistersRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setPendingDocumentsListRegistersRequestStatus', newErrorBuilder(error));
        }
    },

    dismissPendingDocuments({ commit }) {
        commit('setPendingDocumentsList', []);
    },

    async uploadPendingDocumentsRequest({ commit }, { idProduct, items }) {
        commit('setUploadPendingDocumentsRequestStatus', RequestStatusEnum.START);

        const promises = items.map(item => {
            const formData = new FormData();
            formData.append('file', item.file, item.file.name);
            return uploadPendingDocuments(idProduct, formData, item.idSolicitacao);
        });
        Promise.all(promises)
            .then(() => {
                commit('setUploadPendingDocumentsRequestStatus', RequestStatusEnum.SUCCESS);
            })
            .catch(error => {
                commit('setUploadPendingDocumentsRequestStatus', newErrorBuilder(error));
            });
    },

    dismissUpload({ commit }) {
        commit('setUploadPendingDocumentsRequestStatus', RequestStatusEnum.IDLE);
        commit('setPendingDocumentsListRegistersRequestStatus', RequestStatusEnum.IDLE);
    },

    async exportPendingRegisters({ commit }) {
        commit('setExportPendingRegistersStatus', RequestStatusEnum.START);
    },

    async fetchFileTypes({ commit }, { idProduct }) {
        commit('setFileTypesListRequestStatus', RequestStatusEnum.IDLE);
        try {
            const { data } = await fileTypesList(idProduct);
            const result = data.data;
            commit('setFileTypesList', result);
            commit('setFileTypesListRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setFileTypesListRequestStatus', newErrorBuilder(error));
            commit('setFileTypesList', []);
        }
    },

    async listUsers({ commit }, { idProduct, id }) {
        try {
            const { data } = await users(idProduct, id);
            const arr = data.data
                .map(item => managerFromAPI(item))
                .sort((prev, next) => (prev.name > next.name ? 1 : -1));
            if (id === 14) {
                commit('setResaleList', arr);
                return;
            }
            commit('setUserList', arr);
        } catch (error) {
            commit('setUserList', []);
        }
    },

    async getSegments({ commit }, { idProduct }) {
        try {
            const { data } = await listSegments(idProduct);
            commit('setSegmentsList', data.data);
        } catch (error) {
            commit('setSegmentsList', []);
        }
    },

    

    async fetchStates({ commit }) {
        const { data } = await listStates();
        commit('setStates', data.data);
    },

    ...actionsManufacturers,
    ...actionsUsers,

    updateBaseData({ commit }, { selectedProduct }) {
        commit('setBaseData', { selectedProduct });
    },
};
