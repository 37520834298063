<template>
    <v-main>
        <v-row class="mb-3">
            <v-col md="6" sm="12" cols="12" lg="6">
                <MainFilter @onInputChange="filterInputChanged" @onClick="showFilters" />
            </v-col>

            <v-col md="6" sm="12" cols="12" lg="6" align="right">
                <ExportButton @onClick="onExport" />
            </v-col>
        </v-row>

        <v-row v-show="filter" class="mb-4">
            <Filters :loadFilters="false" @onApply="searchListener" />
        </v-row>
        <v-data-table
            :items="manufacturersList"
            hide-default-footer
            class="elevation-0 pb-4 flat-container table-row-clickable"
            :headers="headers"
            @click:row="onClickRow"
        >
            <template v-slot:[`item.status`]="{ item }">
                <v-chip small :class="`status-chip ${getStatus(item.status).color}`">
                    {{ getStatus(item.status).label }}
                </v-chip>
            </template>
            <template v-slot:[`item.relationshipDate`]="{ item }">
                {{ formatDate(item.relationshipDate) }}
            </template>

            <template slot="no-data">
                <div>Não há dados</div>
            </template>

            <template v-slot:footer>
                <v-pagination
                    color="secondary"
                    v-model="currentPage"
                    class="mt-3"
                    :length="manufacturersListTotalPages"
                    :total-visible="7"
                ></v-pagination>
            </template>
        </v-data-table>

        <Loader
            class="mt-6"
            align="center"
            v-if="manufacturersListRequestStatus === RequestStatusEnum.START"
        />
    </v-main>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { RequestStatusEnum, RegistersStatusConsult } from '@/helpers/consts';
import { consultHeader as headers } from '../_headers';
import { formatarDataBarra } from '@/helpers/data';
import Filters from './_Filters';
import { notification } from '@/helpers/notification';
import fileSaver from '@/helpers/fileSaver';
import MainFilter from '@/components/base/mainfilter/MainFilter';
import ExportButton from '@/components/base/ExportButton';
import Loader from '@/components/base/Loader';

export default {
    components: {
        Filters,
        MainFilter,
        ExportButton,
        Loader,
    },
    data() {
        return {
            filter: false,
            filters: {},
            page: 0,
            headers,
            RequestStatusEnum,
            RegistersStatusConsult,
        };
    },
    methods: {
        ...mapActions('cadastros', {
            fetchManufacturers: 'fetchManufacturers',
            exportManufacturers: 'exportManufacturers',
        }),
        ...mapMutations({
            setManufacturer: 'cadastros/setManufacturerDetail',
        }),
        onClickRow(data) {
            this.setManufacturer(data);
        },
        getStatus(key) {
            return RegistersStatusConsult[parseInt(key, 10)];
        },
        formatDate(date) {
            return date ? formatarDataBarra(date) : '';
        },
        async doSearch() {
            let filters = {
                ...this.filters,
                page: this.page,
                limit: 10,
            };
            const payload = {
                idProduct: this.selectedProduct.id,
                filters,
            };
            await this.fetchManufacturers(payload);
        },
        onExport() {
            const payload = {
                idProduct: this.selectedProduct.id,
                filters: this.filters,
            };
            this.exportManufacturers(payload);
        },
        filterInputChanged(value) {
            this.filters.nomeOuDoc = value;
            if (value.length >= 3 || value === '') {
                this.doSearch();
            }
        },
        searchListener(data) {
            const nomeOuDoc = this.filters.nomeOuDoc || '';
            this.filters = { ...data, page: 0, limit: 10 };
            this.filters.nomeOuDoc = nomeOuDoc;
            this.doSearch();
        },
        showFilters() {
            this.filter = !this.filter;
        },
    },
    watch: {
        page() {
            this.doSearch();
        },
        manufacturer(value) {
            if (value) {
                this.$router.push({ path: `/admin/cadastros/produtores/${value.id}/editar` });
            }
        },
        exportManufacturersRequestStatus(newValue) {
            if (newValue === RequestStatusEnum.SUCCESS) {
                const fileName = 'gestao_titulos.xlsx';
                return fileSaver(
                    this.exportManufacturersData.file,
                    this.exportManufacturersData.file.type,
                    fileName
                );
            }
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    'Ocorreu um erro ao fazer a exportação: ' + newValue.message
                );
                return RequestStatusEnum.ERROR;
            }
        },
    },

    computed: {
        ...mapGetters('cadastros', {
            manufacturersList: 'manufacturersList',
            manufacturersListRequestStatus: 'manufacturersListRequestStatus',
            manufacturersListTotalPages: 'manufacturersListTotalPages',
            manufacturer: 'manufacturerDetail',
            selectedProduct: 'selectedProduct',
            exportManufacturersRequestStatus: 'exportManufacturersRequestStatus',
            exportManufacturersData: 'exportManufacturers',
        }),
        currentPage: {
            get() {
                return this.page + 1;
            },
            set(page) {
                this.page = page - 1;
                return page;
            },
        },
    },
    async created() {
        await this.doSearch();
    },
};
</script>
