// '2020-10-14T14:17:17'
// 2020-10-14

export const formatarData = data => {
    const options = {
        timeZone: 'UTC',
    };
    return data ? new Date(data).toLocaleDateString('pt-BR', options) : null;
};

// 14/10/2020
export const formatarDataBarra = data =>
    new Date(data)
        .toISOString()
        .substr(0, 10)
        .split('-')
        .reverse()
        .join('/');

// 14:17:17
export const horaMinutos = hm => new Date(hm).toLocaleString().substring(11, 19);

export const formatDate = date => {
    if (!date) return null;

    const [year, month, day] = date.split(/-|T/);
    return `${day}/${month}/${year}`;
};

export const unFormatDate = date => {
    if (!date) return null;

    const [day, month, year] = date.split('/');
    return `${year}-${month}-${day}`;
};

export const dateStringToISO = date => {
    const dateParts = date.split('/');
    const dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    return dateObject.toISOString();
};

export const formatDateRangeSetKeys = (range, field, obj, sufixes = ['', '_fim']) => {
    obj.filters[field + sufixes[0]] = range[0];
    if (range.length > 1 && range[1] !== range[0]) {
        obj.filters[field + sufixes[1]] = range[1];

        const reverse = new Date(range[0]) > new Date(range[1]);
        if (reverse) {
            const _proxy = obj.filters[field + sufixes[1]];
            obj.filters[field + sufixes[1]] = obj.filters[field + sufixes[0]];
            obj.filters[field + sufixes[0]] = _proxy;
        }
        obj[field + 'Range'] =
            formatDate(obj.filters[field + sufixes[0]]) +
            ' a ' +
            formatDate(obj.filters[field + sufixes[1]]);
    } else {
        obj[field + 'Range'] = formatDate(obj.filters[field]);
        delete obj.filters[field + sufixes[1]];
    }
    return obj[field + 'Range'];
};
