<template>
    <v-data-table
        :headers="headers"
        :items="items"
        hide-default-footer
        class="elevation-0 pb-4 flat-container table-row-clickable"
    >
        <template v-slot:[`item.documentos`]="{ item }">
            <v-btn
                depressed
                color="secondary"
                title="Enviar documentos"
                @click="onDocument(item)"
                small
                :disabled="item.idStatus === '3'"
            >
                Enviar documentos
            </v-btn>
        </template>

        <template v-slot:[`item.status`]="{ item }">
            <v-chip :class="`status-chip ${getStatusColorForChipInverted(item.idStatus)} status-chip-auto`">
                {{ item ? item.status : null }}
            </v-chip>
        </template>

        <template v-slot:[`item.relationship`]="{ item }">
            {{ item ? formatDate(item.relationship) : null }}
        </template>

        <template slot="no-data">
            <div>Não há dados</div>
        </template>

        <template v-slot:footer>
            <v-pagination
                color="secondary"
                v-model="page"
                class="mt-3"
                :total-visible="7"
                :length="pages"
            ></v-pagination>
        </template>
    </v-data-table>
</template>
<script>
import { formatDate } from '@/helpers/data';
import { getStatusColorForChipInverted } from '@/helpers/consts';

export default {
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        headers: {
            type: Array,
        },
        pages: {
            type: Number,
        },
        currentPage: {
            type: Number,
        },
        requestStatus: {},
    },
    data() {
        return {
            page: this.currentPage,
        };
    },
    methods: {
        onDocument(item) {
            this.$emit('onDocumentBtn', item);
        },
        getStatusColorForChipInverted,
        formatDate,
    },
    watch: {
        page() {
            this.$emit('onPager', this.page);
        },
    },
};
</script>
<style scoped>
.v-data-table {
    width: 100%;
}
</style>