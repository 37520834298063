<template>
    <router-view />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    computed: {
        ...mapGetters('base', {
            selectedProduct: 'selectedProduct',
        }),
        ...mapGetters('originacao', {
            statesEnumAsArray: 'statesEnumAsArray',
        }),
    },
    methods: {
        ...mapActions('originacao', {
            updateBaseData: 'updateBaseData',
            getSegments: 'getSegments',
            fetchStates: 'fetchStates',
            listUsers: 'listUsers',
        }),
    },
    async created() {
        if (this.statesEnumAsArray.length === 0) this.fetchStates();
        this.listUsers({ id_produto: this.selectedProduct.id, id: 2 });

        this.updateBaseData({
            selectedProduct: { ...this.selectedProduct },
        });
        await this.getSegments({ id_produto: this.selectedProduct.id });
    },
};
</script>
