<template>
    <router-view />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters('base', {
            selectedProduct: 'selectedProduct',
            originators: 'originators',
        }),
        ...mapGetters('credito', {
            statesEnumAsArray: 'statesEnumAsArray',
        }),
    },
    methods: {
        ...mapActions('credito', {
            updateBaseData: 'updateBaseData',
            listStatusList: 'listStatusList',
            listManagers: 'listManagers',
            fetchStates: 'fetchStates',
        }),
    },
    mounted() {},
    async created() {
        if (this.statesEnumAsArray.length === 0) this.fetchStates();
        this.listStatusList({ id_produto: this.selectedProduct.id });
        this.listManagers({ id: 13 });

        this.updateBaseData({
            selectedProduct: { ...this.selectedProduct },
            originators: this.originators,
        });
    },
};
</script>