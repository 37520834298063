<template>
    <section>
        <Tabs
            ref="tabEl"
            @update="updateTab"
            :tabs="tabs"
            :allowUserChange="true"
            :showCounter="false"
            class="mb-6 mt-3"
        />

        <v-row v-show="currentTab === 'topClientsExposure40'">
            <v-col>
                <v-data-table
                    :headers="headers.top40ClientsExposure"
                    :items="top40ClientsExposureListResult.items"
                    hide-default-footer
                    item-key="clientesQuarentaRaiz"
                    class="elevation-0"
                >
                    <template slot="no-data">
                        <div>Não há dados</div>
                    </template>

                    <template v-slot:[`item.clientesQuarentaSaldoAvencer`]="{ item }">
                        {{ item ? formatarDinheiro(item.clientesQuarentaSaldoAvencer) : null }}
                    </template>

                    <template v-slot:footer>
                        <v-pagination
                            color="secondary"
                            v-model="top40ClientsExposurePage"
                            class="mt-3"
                            :length="top40ClientsExposureListResult.totalPages"
                            :total-visible="7"
                        ></v-pagination>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row v-show="currentTab === 'overdueClients'">
            <v-col>
                <v-data-table
                    :headers="headers.overdueClients"
                    :items="overdueClientsListResult.items"
                    hide-default-footer
                    item-key="clientesAtrasoRaiz"
                    class="elevation-0"
                >
                    <template slot="no-data">
                        <div>Não há dados</div>
                    </template>

                    <template v-slot:[`item.clientesAtrasoSaldoVencido`]="{ item }">
                        <v-chip small :class="`status-chip status-chip-pending`">
                            {{ item ? formatarDinheiro(item.clientesAtrasoSaldoVencido) : null }}
                        </v-chip>
                    </template>

                    <template v-slot:[`item.clientesAtrasoDiasAtraso`]="{ item }">
                        <v-chip small :class="`status-chip status-chip-pending`">
                            {{ $tc('defaults.days', item.clientesAtrasoDiasAtraso) }}
                        </v-chip>
                    </template>

                    <template v-slot:footer>
                        <v-pagination
                            color="secondary"
                            v-model="overdueClientsPage"
                            class="mt-3"
                            :length="overdueClientsListResult.totalPages"
                            :total-visible="7"
                        ></v-pagination>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-col
            v-if="
                top40ClientsExposureListRequestStatus === RequestStatusEnum.START ||
                overdueClientsListRequestStatus === RequestStatusEnum.START
            "
        >
            <Loader class="text-center mt-3" />
        </v-col>
        <v-col
            v-if="
                top40ClientsExposureListRequestStatus.type === RequestStatusEnum.ERROR ||
                overdueClientsListRequestStatus === RequestStatusEnum.ERROR
            "
        >
            <v-alert align="left" justify="center" dense outlined type="error" class="mt-6 ml-3">
                {{ $tc('dashboards.tableError') }}
                <v-btn depressed color="secondary" class="ml-3" @click="load">
                    {{ $tc('defaults.form.reload') }}
                </v-btn>
            </v-alert>
        </v-col>
    </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { RequestStatusEnum } from '@/helpers/consts';
import Tabs from '@/components/base/Tabs';
import Loader from '@/components/base/Loader';
import { formatarDinheiro } from '@/helpers/formatarDinheiro';
import { top40ClientsExposureHeaders, overdueClientsHeaders } from './_headers';

export default {
    components: {
        Loader,
        Tabs,
    },
    mounted() {
        this.load();
    },
    methods: {
        ...mapActions('dashboards', {
            fetchTop40ClientsExposure: 'fetchTop40ClientsExposure',
            fetchOverdueClients: 'fetchOverdueClients',
        }),
        load() {
            this.top40ClientsExposurePage = 1;
            this.overdueClientsPage = 1;
        },
        updateTab(item) {
            this.currentTab = item.path;
        },
        formatarDinheiro,
    },
    computed: {
        ...mapGetters('dashboards', {
            selectedProduct: 'selectedProduct',
            top40ClientsExposureListResult: 'top40ClientsExposureListResult',
            top40ClientsExposureListRequestStatus: 'top40ClientsExposureListRequestStatus',
            overdueClientsListResult: 'overdueClientsListResult',
            overdueClientsListRequestStatus: 'overdueClientsListRequestStatus',
        }),
    },
    data() {
        return {
            RequestStatusEnum,
            tabs: [
                {
                    name: this.$tc('dashboards.analyticsLists.tabs.topClientsExposure40'),
                    path: 'topClientsExposure40',
                },
                {
                    name: this.$tc('dashboards.analyticsLists.tabs.overdueClients'),
                    path: 'overdueClients',
                },
            ],
            currentTab: 'topClientsExposure40',
            top40ClientsExposurePage: null,
            overdueClientsPage: 1,
            headers: {
                top40ClientsExposure: [...top40ClientsExposureHeaders],
                overdueClients: [...overdueClientsHeaders],
            },
        };
    },
    watch: {
        top40ClientsExposurePage(newValue) {
            this.fetchTop40ClientsExposure({
                idProduct: this.selectedProduct.id,
                filters: {
                    page: newValue - 1,
                    limit: 10,
                    orderby: 'clientesQuarentaSaldoAvencer',
                    order: 'DESC',
                },
            });
        },
        overdueClientsPage(newValue) {
            this.fetchOverdueClients({
                idProduct: this.selectedProduct.id,
                filters: { page: newValue - 1, limit: 10 },
            });
        },
        selectedProduct() {
            this.load();
        },
    },
};
</script>