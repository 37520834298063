<template>
    <div>
        <v-row v-if="outgoData">
            <v-col cols="12" sm="12" md="6" lg="2">
                <h3><v-icon class="mr-1">mdi-calendar</v-icon> {{ $tc('outgo.label') }}</h3>
                <span class="value text-h6">{{ formatarData(outgoData.date) }}</span>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="2">
                <span class="value text-h4">{{ outgoData.countTitulos }}</span>
                <h3>
                    <v-icon class="mr-1">mdi-file-document-outline</v-icon>
                    {{ $tc('gestao.titulo', outgoData.countTitulos) }}
                </h3>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="2">
                <h3>Valor nominal</h3>
                <span class="value text-h6"
                    ><sup>R$</sup> {{ formatWithoutSymbol(outgoData.value) }}</span
                >
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="3" v-if="outgoData.statusEligibility === 0">
                <div
                    class="d-flex flex-column justify-space-around align-center elegibility-container"
                >
                    <div align="center">
                        <h3>Elegibilidade</h3>
                        <v-icon v-if="outgoData.cdca === 1" class="mr-1" color="secondary">
                            mdi-file-document-outline
                        </v-icon>
                        <v-icon v-if="outgoData.cprf === 1" class="mr-1" color="altBlue">
                            mdi-file-document-outline
                        </v-icon>
                        <v-icon v-if="outgoData.factory === 1" class="mr-1" color="dark">
                            mdi-file-document-outline
                        </v-icon>
                    </div>
                    <div class="d-flex flex-row mt-3">
                        <button @click="openEligibles" title="Ver títulos elegíveis" class="mr-6">
                            {{ outgoData.countEligibles }}
                            <span>{{ $tc('outgo.eligible.label', outgoData.countEligibles) }}</span>
                        </button>
                        <button @click="openInelegiveis" title="Ver títulos inelegíveis">
                            {{ outgoData.countIneligibles }}
                            <span>{{
                                $tc('outgo.ineligible.label', outgoData.countIneligibles)
                            }}</span>
                        </button>
                    </div>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="12"
                md="6"
                lg="3"
                align="center"
                class="pl-1 pr-1"
                v-if="outgoData.statusEligibility === 1"
            >
                <span>Status</span>
                <v-chip class="mt-3 mb-3 v-chip-processing">
                    {{ $tc('outgo.pending.label') }}
                </v-chip>
                <span>{{ $tc('outgo.pending.warning') }}</span>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="3" v-if="outgoData.status !== 3">
                <v-btn
                    color="secondary"
                    depressed
                    @click="sendOutgo"
                    :title="$tc('outgo.send.label')"
                    outlined
                >
                    <v-icon class="mr-2 v-icon-currency">mdi-currency-usd</v-icon>
                    {{ $tc('outgo.send.label') }}
                </v-btn>
                <button
                    class="mt-3 btn-canceloutgo"
                    @click="cancelOutgo"
                    role="button"
                    :title="$tc('outgo.cancel.label')"
                >
                    {{ $tc('outgo.cancel.label') }}
                </button>
            </v-col>

            <v-col cols="12" sm="12" md="3" v-if="outgoData.status === 3">
                <span>Status</span>
                <v-chip color="secondary" class="mt-3 mb-3">Concluído</v-chip>
            </v-col>
        </v-row>
        <v-dialog content-class="modal-default" v-model="dialogEligibles">
            <header>
                {{ routeType === 'origination' ? 'Títulos Elegíveis' : null }}
                {{ routeType === 'factoring' ? `Remessa #${outgoData.id}` : null }}
                <v-icon role="button" title="Fechar" @click="onCancelEligibles" color="altPrimary">
                    mdi-close-thick
                </v-icon>
            </header>
            <v-main class="pt-6">
                <section>
                    <ModalTitulos
                        ref="modalEligibles"
                        :canSelect="outgoData.statusEligibility === 0 && outgoData.status !== 3"
                        :type="'eligibles'"
                        @onItemRemove="onItemRemove"
                        :id="outgoData.id"
                        v-if="routeType === 'origination'"
                    />
                    <ModalRemessas
                        ref="modalEligibles"
                        :canSelect="outgoData.statusEligibility === 0 && outgoData.status !== 3"
                        :type="'eligibles'"
                        @onItemRemove="onItemRemove"
                        @onItemRemoveNF="onItemRemoveNF"
                        :id="outgoData.id"
                        v-if="routeType === 'factoring'"
                    />
                    <footer align="right" class="mt-6">
                        <v-btn @click="onCancelEligibles" color="secondary" depressed>
                            {{ $tc('defaults.form.close') }}
                        </v-btn>
                        <v-btn
                            @click="onConfirmEligibles"
                            color="secondary"
                            v-if="outgoData.statusEligibility === 0 && outgoData.status !== 3"
                            class="ml-3"
                            depressed
                        >
                            {{ $tc('defaults.form.confirm') }}
                        </v-btn>
                    </footer>
                </section>
            </v-main>
        </v-dialog>

        <v-dialog content-class="modal-default" v-model="dialogInelegiveis">
            <header>
                {{ routeType === 'origination' ? 'Títulos Inelegíveis' : null }}
                {{ routeType === 'factoring' ? `Remessa #${outgoData.id}` : null }}
                <v-icon
                    role="button"
                    title="Fechar"
                    @click="onCancelInelegiveis"
                    color="altPrimary"
                >
                    mdi-close-thick
                </v-icon>
            </header>
            <v-main class="pt-6">
                <section>
                    <ModalTitulos
                        ref="modalIneligibles"
                        :type="'ineligibles'"
                        :id="outgoData.id"
                        v-if="routeType === 'origination'"
                    />
                    <ModalRemessas
                        ref="modalIneligibles"
                        :type="'ineligibles'"
                        :id="outgoData.id"
                        v-if="routeType === 'factoring'"
                    />

                    <footer align="right" class="mt-6">
                        <v-btn
                            @click="onCancelInelegiveis"
                            :id="outgoData.id"
                            color="secondary"
                            depressed
                        >
                            {{ $tc('defaults.form.confirm') }}
                        </v-btn>
                    </footer>
                </section>
            </v-main>
        </v-dialog>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { formatWithoutSymbol } from '@/helpers/formatarDinheiro';
import { formatarData } from '@/helpers/data';
import ModalTitulos from './_ModalTitulos';
import ModalRemessas from './_ModalRemessas';
import { RequestStatusEnum } from '@/helpers/consts';

export default {
    components: {
        ModalTitulos,
        ModalRemessas,
    },
    data() {
        return {
            dialogInelegiveis: false,
            dialogEligibles: false,
            removedItems: [],
            removedNFs: [],
            RequestStatusEnum,
        };
    },
    props: {
        outgoData: {
            type: Object,
            default: () => {
                return {};
            },
        },
        position: {
            type: String,
            defaut: '',
        },
        routeType: {
            type: String,
            default: 'origination',
        },
    },
    computed: {
        ...mapGetters('gestao', {
            selectedProduct: 'selectedProduct',
        }),
        canRemove() {
            return this.statusEligibility === 0 && this.status !== 3;
        },
    },
    methods: {
        formatWithoutSymbol,
        formatarData,
        async sendOutgo() {
            return await this.$dialog.confirm({
                text: this.$tc('outgo.askSend'),
                title: 'Sair',
                actions: {
                    false: {
                        text: 'Não',
                        color: 'danger',
                    },
                    true: {
                        text: 'Sim',
                        color: 'secondary',
                        handle: () => {
                            this.$emit('onSendOutgo', this.outgoData.id);
                        },
                    },
                },
            });
        },
        onCancelInelegiveis() {
            this.dialogInelegiveis = false;
        },
        async openInelegiveis() {
            if (this.outgoData.countIneligibles === 0) {
                return;
            }
            this.dialogInelegiveis = true;
            await this.$nextTick();
            setTimeout(() => {
                this.$refs.modalIneligibles.load();
            });
        },
        onCancelEligibles() {
            this.dialogEligibles = false;
        },
        async openEligibles() {
            this.removedItems = [];
            this.removedNFs = [];
            if (this.outgoData.countEligibles === 0) {
                return;
            }
            this.dialogEligibles = true;
            await this.$nextTick();
            setTimeout(() => {
                this.$refs.modalEligibles.load();
            });
        },
        onItemRemove(items) {
            this.removedItems = [...items];
        },
        onItemRemoveNF(items) {
            this.removedNFs = [...items];
        },
        async cancelOutgo() {
            return await this.$dialog.confirm({
                text: this.$tc('outgo.askCancel'),
                title: 'Sair',
                actions: {
                    false: {
                        text: 'Não',
                        color: 'danger',
                    },
                    true: {
                        text: 'Sim',
                        color: 'secondary',
                        handle: () => {
                            this.$emit('onCancelOutgo', this.outgoData.id);
                        },
                    },
                },
            });
        },
        async onConfirmEligibles() {
            if (this.removedItems.length === 0 && this.removedNFs.length === 0) {
                this.dialogEligibles = false;
                return;
            }

            return await this.$dialog.confirm({
                text:
                    this.routeType === 'origination'
                        ? this.$tc('gestao.askRemove', this.removedItems.length)
                        : this.$tc('gestao.askRemoveFactoring'),
                title: 'Sair',
                actions: {
                    false: {
                        text: 'Não',
                        color: 'danger',
                        handle: () => {
                            this.dialogEligibles = false;
                        },
                    },
                    true: {
                        text: 'Sim',
                        color: 'secondary',
                        handle: () => {
                            this.dialogEligibles = false;
                            this.$emit('onConfirmEligibles', this.outgoData.id, {
                                removedItems: this.removedItems,
                                removedNFs: this.removedNFs,
                            });
                        },
                    },
                },
            });
        },
    },
};
</script>
<style scoped lang="scss">
@import './_OutgoDetail.scss';
</style>