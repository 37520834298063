import createNumberMask from './create_number_mask';
const abbreviate = require('number-abbreviate');
const numAbbr = new abbreviate(['K', 'Mi', 'Bi', 'Tri']);

export const abbreviateNumber = (value, decimals = 1) => {
    return numAbbr.abbreviate(value, decimals);
};

export const capitalize = s => s && s[0].toUpperCase() + s.slice(1);

export const currency = createNumberMask({
    prefix: 'R$',
    allowDecimal: true,
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    decimalSymbol: ',',
    allowNegative: false,
});

export const cleanDocument = document => document.replace(/[^0-9]/g, '');

export const currencyUnmask = maskedValue => {
    if (!maskedValue) {
        return null;
    }
    const decimals = maskedValue.split(',')[1];
    const integerWithPrefix = maskedValue.split(',')[0];
    const integerWithoutPrefix = integerWithPrefix.split('R$')[1];
    let n = Number(integerWithoutPrefix.replace(/\./g, ''));

    if (decimals) {
        n += parseInt(decimals, 10) / 100;
    }
    return n;
};
