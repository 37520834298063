import axios from 'axios';
import store from './store';
import router from './router/router';
import config from './helpers/config';

export const fullfilledRequest = config => {
    store.dispatch('base/startLoading');
    config.headers.authorization = `Bearer ${store.state.auth.token}`;
    return config;
};

export const fullfilledResponse = response => {
    store.dispatch('base/stopLoading');
    return response;
};

export const errorResponse = err => {
    store.dispatch('base/stopLoading');
    if (err.response && err.response.status === 401) {
        store.dispatch('auth/logout');
        if (router.history.current.path.indexOf('/login') < 0) router.push('/login');
        throw err;
    }
    throw err;
};

export const buildInstance = httpClient => {
    const instancia = httpClient.create({
        baseURL: config.apiUrl,
        //Muda depois pra 20 * 1000
        timeout: 100 * 1000,
    });

    instancia.interceptors.request.use(
        config => {
            return fullfilledRequest(config);
        },
        err => Promise.reject(err)
    );

    instancia.interceptors.response.use(fullfilledResponse, errorResponse);
    return instancia;
};

export default buildInstance(axios);
