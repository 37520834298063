<template>
    <section>
        <v-text-field
            v-if="hasInitialInput"
            color="secondary"
            @keyup="onKeyUp"
            class="v-input-filter"
        >
            <template v-slot:label>
                Quer localizar um cliente?
                <v-icon style="vertical-align: middle"> mdi-file-find </v-icon>
            </template>
        </v-text-field>
        <v-btn
            depressed
            v-if="hasExtraFilters"
            outlined
            color="secondary"
            @click="onFilterClick"
            large
        >
            Filtros
            <v-icon color="secondary" class="ml-5">mdi-filter-variant</v-icon>
        </v-btn>
    </section>
</template>

<script>
export default {
    props: {
        hasExtraFilters: {
            type: Boolean,
            default: true,
        },
        hasInitialInput: {
            type: Boolean,
            default: true,
        },
    },
    data: () => {
        return {
            timer: null,
        };
    },
    methods: {
        onFilterClick() {
            this.$emit('onClick');
        },
        onKeyUp(event) {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                this.$emit('onInputChange', event.target.value);
            }, 200);
        },
    },
};
</script>

<style scoped>
section {
    display: flex;
    flex-direction: row;
}
section .v-btn {
    margin-left: 1rem;
}
.v-input.v-input-filter {
    padding-top: 0.5rem;
}
</style>
