<template>
    <router-view />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters('base', {
            selectedProduct: 'selectedProduct',
        }),
        ...mapGetters('cadastros', {
            statesEnumAsArray: 'statesEnumAsArray',
        }),
    },
    methods: {
        ...mapActions('cadastros', {
            updateBaseData: 'updateBaseData',
            fetchFileTypes: 'fetchFileTypes',
            fetchStates: 'fetchStates',
            listUsers: 'listUsers',
            getSegments: 'getSegments',
        }),
    },
    async created() {
        this.fetchFileTypes({ idProduct: this.selectedProduct.id });
        if (this.statesEnumAsArray.length === 0) this.fetchStates();
        this.getSegments({ idProduct: this.selectedProduct.id });
        this.listUsers({ idProduct: this.selectedProduct.id, id: 13 });
        this.listUsers({ idProduct: this.selectedProduct.id, id: 14 });
        this.updateBaseData({
            selectedProduct: { ...this.selectedProduct },
        });
    },
};
</script>
