import axios from '../../axios.js';

const getOriginacoes = (idProduct, tipo, filters) =>
    axios.get(`/v2/${idProduct}/originacao/clientes/${tipo}?${filters}`);
const getNotas = (idProduct, id_cliente, tipo, filters) =>
    axios.get(`/v2/${idProduct}/originacao/cliente/${id_cliente}/notas/${tipo}?${filters}`);
const listStates = () => axios.get(`/v2/enums/estados`);
const users = (idProduct, id) => axios.get(`/v2/${idProduct}/cadastro/tipo/${id}`);
const getStatusLimit = idProduct => axios.get(`/v2/${idProduct}/limite/status`);
const listSegments = (id) => axios.get(`/v2/cadastro/produtos/${id}/segmentos`);
const getSimulacao = (id, filters) => axios.get(`/v2/${id}/originacao/simulacao?${filters}`);
const getAvailability = (id, filters) => axios.get(`/v2/${id}/originacao/pl/disponivel?data=${filters}`);
const selecaoManual = (id, payload) => axios.post(`/v2/${id}/originacao/selecaomanual`, payload);
const fetchOriginacao = (id, idProduct) => axios.get(`/v2/${idProduct}/originacao/${id}`);
const confirmOriginacao = (id, idProduct, payload) => axios.patch(`/v2/${idProduct}/originacao/confirmacao/${id}`, payload);
const exportOriginacao = (id, idProduct) => axios.get(`/v2/${idProduct}/originacao/download/${id}`, {
    responseType: 'blob',
});
const selecaoSmart = (idProduct, payload) => axios.post(`/v2/${idProduct}/originacao/smart`, payload);

export default {
    getOriginacoes,
    getNotas,
    listStates,
    users,
    getStatusLimit,
    listSegments,
    getSimulacao,
    getAvailability,
    selecaoManual,
    fetchOriginacao,
    confirmOriginacao,
    exportOriginacao,
    selecaoSmart
};
