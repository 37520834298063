<template>
    <v-main>
        <h3 class="mb-4 mt-3">
            <v-icon>mdi-account-circle-outline</v-icon>
            {{ $tc('cadastros.accessProfile.new') }}
        </h3>
        <div class="pt-9 pr-9 pl-9 pb-main-default">
            <v-container>
                <v-form
                    :lazy-validation="true"
                    class="form-custom-disabled"
                    ref="form"
                    v-model="valid"
                >
                    <v-row>
                        <v-col md="4">
                            <v-text-field
                                color="primary"
                                v-model="email"
                                label="E-mail"
                                class="v-input-default"
                                :rules="[rules.required, rules.email]"
                                :validate-on-blur="true"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="4">
                            <v-select
                                color="secondary"
                                :items="profiles"
                                v-model="profile"
                                disabled
                                label="Perfil"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-btn
                        @click="onSave"
                        color="secondary"
                        :disabled="!valid"
                        depressed
                        class="mt-6 v-btn-responsive"
                    >
                        Cadastrar
                    </v-btn>
                </v-form>
                <Loader
                    class="text-center mt-3"
                    v-if="createUserRequestStatus === RequestStatusEnum.START"
                    mode="overlay"
                />
            </v-container>
        </div>
    </v-main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { email as emailValidator } from '@/helpers/validators';
import { RequestStatusEnum } from '@/helpers/consts';
import { notification } from '@/helpers/notification';
import Loader from '@/components/base/Loader';

export default {
    components: {
        Loader
    },
    data: () => ({
        profiles: ['Tipo de Perfil'],
        email: null,
        profile: 'Tipo de Perfil',
        valid: false,
        rules: {
            required: value => !!value || 'Campo obrigatório',
            email: v => emailValidator(v) || 'E-mail inválido',
        },
        RequestStatusEnum,
    }),
    computed: {
        ...mapGetters('cadastros', {
            createUserRequestStatus: 'createUserRequestStatus',
        }),
    },
    watch: {
        createUserRequestStatus(newValue) {
            if (newValue === RequestStatusEnum.SUCCESS) {
                notification(
                    this.$dialog.notify.success,
                    this.$tc('cadastros.users.create.success')
                );
                this.$router.push(`/admin/cadastros/usuarios`);
                return RequestStatusEnum.SUCCESS;
            }
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    this.$t('cadastros.users.create.error', { msg: newValue.message })
                );
                return RequestStatusEnum.ERROR;
            }
        },
    },
    methods: {
        ...mapActions('cadastros', {
            createUser: 'createUser',
        }),
        onSave() {
            if (!this.$refs.form.validate()) return;
            this.createUser({ email: this.email });
        },
    },
};
</script>
