import { RequestStatusEnum, newErrorBuilder } from '@/helpers/consts';
import queryString from '@/helpers/dtos/queryString';
import {
    _fetchDefaultParserPagination,
    getFirstItemFromResponseArray,
    _fetchDefaultParser,
} from '@/helpers/store';

import {
    getUFLimiteUsado,
    getBalancesPerUF,
    getAverageClientExposure,
    getClientsCount,
    getDelayGrowLastDays,
    getBalanceDue,
    getOverdueBalance,
    getTop10Exposure,
    getAvailableCash,
    getTop40ClientsExposure,
    getOverdueClients,
    getWallet,
    getUpcomingDues,
} from '../service';
import dashboardWalletFromAPI from '../dtos/dashboardWalletFromAPI';
import balanceDueFromAPI from '../dtos/balanceDueFromAPI';
import limitsUsagePerUFFromAPI from '../dtos/limitsUsagePerUFFromAPI';
import upcomingDueFromAPI from '../dtos/upcomingDueFromAPI';

export default {
    async fetchUpcomingDues({ commit }, { idProduct, filters }) {
        commit('setUpcomingDuesRequestStatus', RequestStatusEnum.START);

        try {
            const { data } = await getUpcomingDues(idProduct, queryString(filters, {}));
            const content = data.data.content.map(item => upcomingDueFromAPI(item));
            _fetchDefaultParser(commit, content, null, 'UpcomingDues');
        } catch (error) {
            commit('setUpcomingDuesRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchFundWallet({ commit }, { idProduct, filters }) {
        commit('setFundWalletRequestStatus', RequestStatusEnum.START);

        try {
            const { data } = await getWallet(idProduct, queryString(filters, {}));
            _fetchDefaultParser(commit, data.data.content, dashboardWalletFromAPI, 'FundWallet');
        } catch (error) {
            commit('setFundWalletRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchBalancesPerUF({ commit }, { idProduct, filters }) {
        commit('setBalancesPerUFRequestStatus', RequestStatusEnum.START);
        try {
            const { data } = await getBalancesPerUF(idProduct, queryString(filters, {}));
            _fetchDefaultParser(commit, data.data.content, null, 'BalancesPerUF');
        } catch (error) {
            commit('setBalancesPerUFRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchLimitsUsagePerUF({ commit }, { idProduct, filters }) {
        commit('setLimitsUsagePerUFRequestStatus', RequestStatusEnum.START);

        try {
            const { data } = await getUFLimiteUsado(idProduct, queryString(filters, {}));
            const content = data.data.content.map(item => limitsUsagePerUFFromAPI(item));
            _fetchDefaultParser(commit, content, null, 'LimitsUsagePerUF');
        } catch (error) {
            commit('setLimitsUsagePerUFRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchAverageClientExposure({ commit }, { idProduct, filters }) {
        commit('setAverageClientExposureRequestStatus', RequestStatusEnum.START);

        try {
            const { data } = await getAverageClientExposure(idProduct, queryString(filters, {}));
            _fetchDefaultParser(
                commit,
                getFirstItemFromResponseArray(data),
                null,
                'AverageClientExposure'
            );
        } catch (error) {
            commit('setAverageClientExposureRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchClientsCount({ commit }, { idProduct, filters }) {
        commit('setClientsCountRequestStatus', RequestStatusEnum.START);
        try {
            const { data } = await getClientsCount(idProduct, queryString(filters, {}));
            _fetchDefaultParser(commit, getFirstItemFromResponseArray(data), null, 'ClientsCount');
        } catch (error) {
            commit('setClientsCountRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchDelayGrowLastDays({ commit }, { idProduct, filters }) {
        commit('setDelayGrowLastDaysRequestStatus', RequestStatusEnum.START);

        try {
            const { data } = await getDelayGrowLastDays(idProduct, queryString(filters, {}));
            _fetchDefaultParser(
                commit,
                getFirstItemFromResponseArray(data),
                null,
                'DelayGrowLastDays'
            );
        } catch (error) {
            commit('setDelayGrowLastDaysRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchBalanceDue({ commit }, { idProduct, filters }) {
        commit('setBalanceDueRequestStatus', RequestStatusEnum.START);

        try {
            const { data } = await getBalanceDue(idProduct, queryString(filters, {}));

            _fetchDefaultParser(
                commit,
                getFirstItemFromResponseArray(data),
                balanceDueFromAPI,
                'BalanceDue'
            );
        } catch (error) {
            commit('setBalanceDueRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchOverdueBalance({ commit }, { idProduct, filters }) {
        commit('setOverdueBalanceRequestStatus', RequestStatusEnum.START);

        try {
            const { data } = await getOverdueBalance(idProduct, queryString(filters, {}));
            _fetchDefaultParser(
                commit,
                getFirstItemFromResponseArray(data),
                null,
                'OverdueBalance'
            );
        } catch (error) {
            commit('setOverdueBalanceRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchTop10Exposure({ commit }, { idProduct, filters }) {
        commit('setTop10ExposureRequestStatus', RequestStatusEnum.START);

        try {
            const { data } = await getTop10Exposure(idProduct, queryString(filters, {}));
            _fetchDefaultParser(commit, getFirstItemFromResponseArray(data), null, 'Top10Exposure');
        } catch (error) {
            commit('setTop10ExposureRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchAvailableCash({ commit }, { idProduct, filters }) {
        commit('setAvailableCashRequestStatus', RequestStatusEnum.START);

        try {
            const { data } = await getAvailableCash(idProduct, queryString(filters, {}));
            _fetchDefaultParser(commit, getFirstItemFromResponseArray(data), null, 'AvailableCash');
        } catch (error) {
            commit('setAvailableCashRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchTop40ClientsExposure({ commit }, { idProduct, filters }) {
        commit('setTop40ClientsExposureListRequestStatus', RequestStatusEnum.START);
        try {
            const params = queryString(filters, {});
            const { data } = await getTop40ClientsExposure(idProduct, params);
            _fetchDefaultParserPagination(commit, data, null, 'Top40ClientsExposureList');
        } catch (error) {
            commit('setTop40ClientsExposureListRequestStatus', newErrorBuilder(error));
        }
    },

    async fetchOverdueClients({ commit }, { idProduct, filters }) {
        commit('setOverdueClientsListRequestStatus', RequestStatusEnum.START);
        try {
            const params = queryString(filters, {});
            const { data } = await getOverdueClients(idProduct, params);
            _fetchDefaultParserPagination(commit, data, null, 'OverdueClientsList');
        } catch (error) {
            commit('setOverdueClientsListRequestStatus', newErrorBuilder(error));
        }
    },

    resetState({ commit }) {
        commit('resetState');
    },

    updateBaseData({ commit }, { selectedProduct }) {
        commit('setBaseData', { selectedProduct });
    },
};
