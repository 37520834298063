<template>
    <v-main class="ganeralWidget">
        <v-progress-linear
            :active="loading"
            color="secondary"
            :indeterminate="true"
            height="4"
            class="loading"
        />
        <Navbar />
        <Header :productName="selectedProduct ? selectedProduct.register.name : ''" />
        <v-row class="mb-0 pb-6">
            <div id="menu-container">
                <Sidebar />
            </div>
            <div v-if="profile" class="divStyleRouterView">
                <router-view v-if="allowed" class="styleRouterView" />

                <v-main class="mt-8 ml-4" v-else>
                    <div class="pt-16 pb-16 pr-9 pl-9">
                        <v-alert align="left" justify="center" dense outlined type="error" class="">
                            Você não tem acesso a essa url
                        </v-alert>
                    </div>
                </v-main>
            </div>
            <v-col v-else lg="10" md="9" sm="12" xs="12" class="mt-12">
                <Loader justify="center" mode="overlay" />
            </v-col>
        </v-row>
    </v-main>
</template>

<script>
import Header from './components/header/Header.vue';
import Sidebar from './components/menu/Sidebar.vue';
import Navbar from './components/navbar/Navbar.vue';
import Loader from '@/components/base/Loader';
import { mapGetters, mapActions } from 'vuex';

export default {
    components: {
        Header,
        Sidebar,
        Navbar,
        Loader,
    },
    data() {
        return {
            allowed: true,
        };
    },
    methods: {
        ...mapActions('auth', {
            fetchProfile: 'fetchProfile',
        }),
        checkAllowed(to, userRole = 'ADMIN') {
            if (!to || !to.meta || !to.meta.allowedRoles) {
                return true;
            }
            if (to.meta.allowedRoles.indexOf(userRole) >= 0) {
                return true;
            }
            return false;
        },
    },
    computed: {
        ...mapGetters('base', {
            loading: 'loading',
            selectedProduct: 'selectedProduct',
        }),
        ...mapGetters('auth', {
            profile: 'profile',
        }),
    },
    created() {
        this.fetchProfile();
        window.addEventListener('storage', e => {
            if (e.key.toLowerCase() === 'selectedproduct') {
                window.location.href = '/admin/dashboard';
            }
        });
    },
    mounted() {
        this.allowed = this.checkAllowed(this.$route);
    },
    beforeRouteUpdate(to, _, next) {
        this.allowed = this.checkAllowed(to);
        next();
    },
};
</script>
<style scoped>
@import './Layout.scss';
</style>
