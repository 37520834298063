const { abbreviateNumber } = require('@/helpers/masks');
import { formatarDinheiro } from '@/helpers/formatarDinheiro';

export const balancesPerUFChartOptions = {
    chart: {
        height: 380,
        type: 'pie',
    },
    colors: [
        '#257452',
        '#2D8B65',
        '#38A67A',
        '#90CAAE',
        '#C9DEB3',
        '#84B467',
        '#669150',
        '#74BCBC',
    ],
    legend: {
        show: !0,
        position: 'bottom',
        horizontalAlign: 'center',
        verticalAlign: 'middle',
        floating: !1,
        fontSize: '10px',
        offsetX: 0,
    },
    tooltip: {
        shared: !0,
        intersect: !1,
        y: {
            formatter: function(e) {
                return abbreviateNumber(e);
            },
        },
    },
    xaxis: {
        labels: {
            style: {
                colors: '#AAAAAA',
            },
        },
    },
    yaxis: {
        labels: {
            style: {
                colors: '#AAAAAA',
            },
        },
    },
    responsive: [
        {
            breakpoint: 600,
            options: {
                chart: {
                    height: 250,
                },
                legend: {
                    show: !1,
                },
            },
        },
    ],
};

export const limitsPerUFChartOptions = {
    legend: {
        onItemClick: {
            toggleDataSeries: false,
        },
        onItemHover: {
            highlightDataSeries: false,
        },
    },
    chart: {
        type: 'bar',
        height: 350,
        stacked: !0,
        toolbar: {
            show: !1,
        },
        zoom: {
            enabled: !0,
        },
    },
    plotOptions: {
        bar: {
            horizontal: !1,
            columnWidth: '20%',
        },
    },
    dataLabels: {
        enabled: !1,
    },
    xaxis: {
        categories: [],
        labels: {
            style: {
                colors: '#AAAAAA',
            },
        },
    },
    yaxis: {
        labels: {
            formatter: value => formatarDinheiro(value),
            style: {
                colors: '#AAAAAA',
            },
        },
    },
    tooltip: {
        shared: !0,
        intersect: !1,
        y: {
            formatter: function(e) {
                return formatarDinheiro(e);
            },
        },
    },
    colors: ['#297453', '#45cb85'],
    fill: {
        opacity: 1,
    },
    grid: {
        show: false,
    },
};

export const carteiraDoFundoOptions = {
    chart: {
        height: 350,
        type: 'area',
        zoom: {
            enabled: 1,
        },
        toolbar: {
            show: 1,
        },
    },
    fill: {
        colors: ['#45cb85', 'white'],
    },
    dataLabels: {
        enabled: true,
        formatter: value => abbreviateNumber(value),
        enabledOnSeries: [0],
        style: {
            colors: ['#45cb85'],
            fontSize: '12px',
        },
        offsetY: 0,
        offsetX: 9,
    },
    stroke: {
        width: [4, 4],
        curve: 'straight',
        dashArray: [0, 5],
    },
    colors: ['#45cb85', '#333'],
    markers: {
        size: 0,
        hover: {
            sizeOffset: 6,
        },
    },
    xaxis: {
        type: 'date',
        categories: [],
        labels: {
            style: {
                colors: '#AAAAAA',
            },
        },
    },
    yaxis: {
        labels: {
            formatter: value => formatarDinheiro(value),
            style: {
                colors: '#AAAAAA',
            },
        },
    },
    grid: {
        show: false,
    },
    tooltip: {
        y: {
            formatter: function(e) {
                return formatarDinheiro(e);
            },
        },
    },
};

export const vencimentosFuturoOptions = {
    chart: {
        type: 'bar',
        height: 374,
        stacked: !0,
        toolbar: {
            show: !1,
        },
        zoom: {
            enabled: !0,
        },
    },
    grid: {
        show: false,
    },
    legend: {
        onItemClick: {
            toggleDataSeries: false,
        },
        onItemHover: {
            highlightDataSeries: false,
        },
    },
    plotOptions: {
        bar: {
            horizontal: !1,
            columnWidth: '20%',
        },
    },
    dataLabels: {
        enabled: !1,
    },
    yaxis: {
        labels: {
            formatter: value => formatarDinheiro(value),
            style: {
                colors: '#AAAAAA',
            },
        },
    },
    xaxis: {
        categories: [],
        labels: {
            style: {
                colors: '#AAAAAA',
            },
        },
    },
    tooltip: {
        shared: !0,
        intersect: !1,
        y: {
            formatter: function(e) {
                return abbreviateNumber(e);
            },
        },
    },
    colors: ['#297453', '#45cb85'],
    fill: {
        opacity: 1,
    },
};
