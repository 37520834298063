<template>
    <section>
        <apexchart
            v-if="upcomingDuesRequestStatus === RequestStatusEnum.SUCCESS"
            height="400px"
            :series="series"
            :options="options"
        ></apexchart>
        <Loader
            v-if="upcomingDuesRequestStatus === RequestStatusEnum.START"
            class="text-center mt-3"
        />
        <v-alert
            v-if="upcomingDuesRequestStatus.type === RequestStatusEnum.ERROR"
            align="left"
            justify="center"
            dense
            outlined
            type="error"
            class="mt-6 ml-3 mr-3"
        >
            {{ $tc('dashboards.error') }}
            <v-btn depressed color="secondary" class="ml-3" @click="load">
                {{ $tc('defaults.form.reload') }}</v-btn
            >
        </v-alert>
    </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { RequestStatusEnum } from '@/helpers/consts';
import Loader from '@/components/base/Loader';
import { vencimentosFuturoOptions } from './_options';
import { calculateWithDecimals } from '@/helpers/percentage';
import { capitalize, abbreviateNumber } from '@/helpers/masks';

export default {
    components: {
        Loader,
    },
    mounted() {
        this.load();
    },
    methods: {
        ...mapActions('dashboards', {
            fetchUpcomingDues: 'fetchUpcomingDues',
        }),
        load() {
            this.fetchUpcomingDues({
                idProduct: this.selectedProduct.id,
                filters: {},
            });
        },
    },
    computed: {
        ...mapGetters('dashboards', {
            selectedProduct: 'selectedProduct',
            upcomingDues: 'upcomingDues',
            upcomingDuesRequestStatus: 'upcomingDuesRequestStatus',
        }),
    },
    watch: {
        selectedProduct() {
            this.load();
        },

        upcomingDues(newValue) {
            let dueValue = [];
            let value = [];
            const today = new Date();
            [...newValue]
                .filter(item => {
                    return new Date(item.date) > today;
                })
                .sort((prev, next) => {
                    return new Date(prev) > new Date(next) ? -1 : 1;
                })
                .forEach(item => {
                    const perc = calculateWithDecimals(item.value, item.dueValue + item.value, 0);
                    const date = new Date(item.date);
                    const monthAsString = date
                        .toLocaleDateString('pt-BR', { month: 'short' })
                        .split('.')[0];

                    const percStr = `${capitalize(
                        monthAsString
                    )} ${date.getFullYear()} ${abbreviateNumber(item.value)} (${perc}%)`;
                    dueValue.push(item.dueValue);
                    value.push(item.value);
                    this.options.xaxis.categories.push(percStr);
                });

            this.series = [
                {
                    name: 'Valor Antecipado',
                    data: [...value],
                },
                {
                    name: 'Valor A vencer',
                    data: [...dueValue],
                },
            ];
        },
    },
    data() {
        return {
            RequestStatusEnum,
            options: {
                ...vencimentosFuturoOptions,
            },
            series: [],
        };
    },
};
</script>