<template>
    <v-main>
        <div class="mt-6">
            <section
                class="consolidados-container"
                v-if="outgoesListRequestStatus === RequestStatusEnum.SUCCESS"
            >
                <v-col v-for="(outgo, index) in outgoesListResult.items" :key="index">
                    <OutogoDetail :outgoData="outgo" :routeType="routeType" />
                </v-col>

                <v-alert
                    align="left"
                    justify="center"
                    dense
                    outlined
                    type="warning"
                    v-if="outgoesListResult.totalPages === 0"
                >
                    Não há dados
                </v-alert>
            </section>
            <Loader
                v-if="outgoesListRequestStatus === RequestStatusEnum.START"
                class="text-center mt-3"
            />

            <v-row
                align="center"
                justify="center"
                class="mt-6"
                v-if="
                    outgoesListRequestStatus === RequestStatusEnum.SUCCESS &&
                    outgoesListResult.totalPages > 0
                "
            >
                <v-pagination
                    color="secondary"
                    v-model="page"
                    class="mt-3"
                    :length="outgoesListResult.totalPages"
                    :total-visible="7"
                ></v-pagination>
            </v-row>
        </div>
    </v-main>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import OutogoDetail from '../components/_OutgoDetail';
import Loader from '@/components/base/Loader';
import { RequestStatusEnum } from '@/helpers/consts';

export default {
    components: {
        OutogoDetail,
        Loader,
    },
    props: {
        outgoesFilterType: {
            type: String,
            default: null,
        },
        routeType: {
            type: String,
            default: 'origination',
        },
    },
    data() {
        return {
            filters: {
                page: 0,
                limit: 10,
            },
            page: 1,
            lastSearchFilters: {},
            RequestStatusEnum,
        };
    },
    computed: {
        ...mapGetters('gestao', {
            outgoesListResult: 'outgoesListResult',
            outgoesListRequestStatus: 'outgoesListRequestStatus',
            selectedProduct: 'selectedProduct',
        }),
    },
    methods: {
        ...mapActions('gestao', {
            fetchOutgoes: 'fetchOutgoes',
        }),

        doSearch() {
            if (!this.filters.nomeOuDoc) {
                delete this.filters.nomeOuDoc;
            }
            this.lastSearchFilters = { ...this.filters };
            this.fetchOutgoes({
                idProduct: this.selectedProduct.id,
                status: 3,
                filters: this.filters,
            });
        },
    },
    mounted() {
        this.filters.tipo = this.outgoesFilterType;
        this.doSearch();
    },
    watch: {
        page() {
            this.filters.page = this.page - 1;
            this.doSearch();
        },
    },
};
</script>
<style scoped lang="scss">
.consolidados-container {
    .col {
        margin-bottom: 1rem;
    }
}
</style>