const Produtores = () => import('@/mfes/cadastros/views/Produtores');
const EditarProdutor = () => import('@/mfes/cadastros/views/produtores/Detalhes/EditarProdutor');
const Users = () => import('@/mfes/cadastros/views/Users');
const NovoUser = () => import('@/mfes/cadastros/views/users/Novo');
const EditarUser = () => import('@/mfes/cadastros/views/users/Editar');

export default [
    {
        path: 'produtores/:id/editar',
        name: 'Editar Produtor',
        component: EditarProdutor,
        meta: { title: 'Editar Produtor' },
    },
    {
        path: 'produtores/:step',
        name: 'Produtores',
        component: Produtores,
        meta: { title: 'Produtores' },
    },
    {
        path: 'produtores',
        redirect: 'produtores/consultar',
    },
    {
        path: 'usuarios/novo',
        name: 'PerfisNovo',
        component: NovoUser,
        meta: { title: 'Perfil de Acesso' },
    },
    {
        path: 'usuarios/:id/editar',
        name: 'PerfisDetalhe',
        component: EditarUser,
        meta: { title: 'Perfil de Acesso' },
    },
    {
        path: 'usuarios/:step',
        name: 'Perfis',
        component: Users,
        meta: { title: 'Perfil de Acesso' },
    },
    {
        path: 'usuarios',
        redirect: 'usuarios/consultar',
    },
];
