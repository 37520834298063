<template>
    <v-form v-if="manufacturer.document > 11" class="form-custom-disabled">
        <v-row>
            <v-col cols="12" sm="12" md="6">
                <v-text-field
                    color="primary"
                    v-model="manufacturer.customer"
                    label="Razão Social"
                    disabled
                    class="v-input-default"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
                <v-text-field
                    color="primary"
                    v-model="manufacturer.document"
                    label="CNPJ"
                    disabled
                    class="v-input-default"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
                <v-text-field
                    color="primary"
                    v-model="manufacturer.email"
                    label="E-mail"
                    disabled
                    class="v-input-default"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
                <v-text-field
                    color="primary"
                    v-model="manufacturer.cellphone"
                    label="Celular"
                    disabled
                    class="v-input-default"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
                <v-text-field
                    color="primary"
                    v-model="manufacturer.phone"
                    label="Telefone"
                    disabled
                    class="v-input-default"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
                <v-text-field
                    color="primary"
                    v-model="manufacturer.stateSubscription"
                    label="Inscrição Estadual"
                    disabled
                    class="v-input-default"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
                <v-text-field
                    color="primary"
                    v-model="manufacturer.ruralSubscription"
                    label="Inscrição Rural"
                    disabled
                    class="v-input-default"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
                <v-text-field
                    color="primary"
                    v-model="manufacturer.site"
                    label="Site"
                    disabled
                    class="v-input-default"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12">
                <label>Dados de Responsável</label>
            </v-col>
            <v-col cols="12" sm="12" md="4">
                <v-text-field
                    color="primary"
                    v-model="manufacturer.responsible"
                    label="Nome"
                    class="v-input-default"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
                <v-text-field
                    color="primary"
                    v-model="manufacturer.responsibleCellphone"
                    label="Celular"
                    class="v-input-default"
                    v-mask="'(##)#####-####'"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
                <v-text-field
                    color="primary"
                    v-model="manufacturer.responsibleEmail"
                    label="E-mail"
                    class="v-input-default"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-btn @click="saveData"  color="secondary" depressed class="mt-6 v-btn-responsive">
            {{ $tc('defaults.form.save') }}
        </v-btn>
    </v-form>
    <v-form v-else class="form-custom-disabled">
        <v-row>
            <v-col cols="12" sm="12" md="8">
                <v-text-field
                    color="primary"
                    v-model="manufacturer.responsible"
                    label="Nome Compleo"
                    class="v-input-default"
                    disabled
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
                <v-text-field
                    color="primary"
                    v-model="manufacturer.document"
                    label="CPF"
                    class="v-input-default"
                    disabled
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
                <v-text-field
                    color="primary"
                    v-model="manufacturer.responsibleEmail"
                    label="E-mail"
                    class="v-input-default"
                    disabled
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
                <v-text-field
                    color="primary"
                    v-model="manufacturer.responsibleCellphone"
                    label="Celular"
                    class="v-input-default"
                    disabled
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
                <v-text-field
                    color="primary"
                    v-model="manufacturer.responsiblePhone"
                    label="Telefone"
                    class="v-input-default"
                    disabled
                ></v-text-field>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { cleanDocument } from '@/helpers/masks';
import { RequestStatusEnum } from '@/helpers/consts';
import { notification } from '@/helpers/notification';

export default {
    computed: {
        ...mapGetters({
            selectedProduct: 'cadastros/selectedProduct',
            manufacturer: 'cadastros/manufacturerDetail',
            updateManufacturerDetailGeneralRequestStatus:
                'cadastros/updateManufacturerDetailGeneralRequestStatus',
        }),
    },
    watch: {
        updateManufacturerDetailGeneralRequestStatus(value) {
            if (value === RequestStatusEnum.SUCCESS) {
                notification(this.$dialog.notify.success, 'Dados atualizados com sucesso');
                return RequestStatusEnum.ERROR;
            }
        },
    },
    methods: {
        ...mapActions('cadastros', {
            updateManufacturerGeneral: 'updateManufacturerGeneral',
        }),
        saveData() {
            const payload = {
                responsavel: this.manufacturer.responsible,
                responsavel_celular: this.manufacturer.responsibleCellphone
                    ? cleanDocument(this.manufacturer.responsibleCellphone)
                    : '',
                responsavel_email: this.manufacturer.responsibleEmail,
            };
            this.updateManufacturerGeneral({
                id: this.manufacturer.id,
                idProduct: this.selectedProduct.id,
                payload,
            });
        },
    },
};
</script>

<style></style>
