<template>
    <v-main>
        <v-form ref="form" class="ml-3 mr-3">
            <v-row>
                <v-col cols="12" sm="12" md="3" lg="3">
                    <v-select
                        color="secondary"
                        v-model="filters.id_fornecedor"
                        :items="suppliers"
                        item-text="name"
                        item-value="id"
                        label="Fornecedor"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3">
                    <v-select
                        color="secondary"
                        v-model="filters.estado"
                        :items="states"
                        label="UF"
                    ></v-select>
                </v-col>

                <v-col cols="12" sm="12" md="3" lg="3">
                    <v-select
                        color="secondary"
                        :items="segments"
                        v-model="filters.segmento"
                        label="Rating/Segmento"
                    ></v-select>
                </v-col>

                <v-col cols="12" sm="12" md="6">
                    <v-menu
                        ref="menuEmissao"
                        v-model="menuEmissao"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="emissaoRange"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                class="v-input-default"
                                color="secondary"
                                v-model="emissaoRange"
                                label="Data (Inicio/fim) emissão"
                                prepend-icon="event"
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-if="menuEmissao"
                            v-model="emissao"
                            no-title
                            range
                            scrollable
                            locale="pt-br"
                        >
                            <v-spacer></v-spacer>
                            <v-btn color="secondary" depressed @click="menuEmissao = false"
                                >Cancelar</v-btn
                            >
                            <v-btn
                                color="secondary"
                                depressed
                                @click="$refs.menuEmissao.save(formatDateRange(emissao, 'emissao'))"
                                >Salvar</v-btn
                            >
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <v-menu
                        ref="menuVencimento"
                        v-model="menuVencimento"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="vencimentoRange"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                class="v-input-default"
                                color="secondary"
                                v-model="vencimentoRange"
                                label="Data (Inicio/fim) vencimento"
                                prepend-icon="event"
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-if="menuVencimento"
                            v-model="vencimento"
                            no-title
                            range
                            scrollable
                            locale="pt-br"
                        >
                            <v-spacer></v-spacer>
                            <v-btn color="secondary" depressed @click="menuVencimento = false"
                                >Cancelar</v-btn
                            >
                            <v-btn
                                color="secondary"
                                depressed
                                @click="
                                    $refs.menuVencimento.save(
                                        formatDateRange(vencimento, 'vencimento')
                                    )
                                "
                                >Salvar</v-btn
                            >
                        </v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="mt-3 container-filters-buttons">
                    <v-btn
                        depressed
                        @click="apply"
                        color="secondary"
                        class="v-btn-padded v-btn-responsive"
                    >
                        Aplicar
                    </v-btn>

                    <v-btn
                        outlined
                        @click="onFilterClear"
                        depressed
                        color="secondary"
                        class="v-btn-padded v-btn-responsive"
                    >
                        Limpar
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-main>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatDateRangeSetKeys } from '@/helpers/data';

export default {
    props: {
        loadFilters: {
            type: Boolean,
            default: true,
        },
        enableStatusOnly: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        filters: {},
        managers: [],
        menuEmissao: false,
        emissao: null,
        emissaoRange: null,
        emissao_fim: null,
        menuVencimento: false,
        vencimento: null,
        vencimento_fim: null,
        vencimentoRange: null,
    }),

    computed: {
        ...mapGetters('originacao', {
            selectedProduct: 'selectedProduct',
            states: 'statesEnumAsArray',
            segments: 'segmentsList',
            suppliers: 'usersList',
        }),

        products() {
            if (!this.originators) {
                return [];
            }
            const prod = this.originators.flatMap(el => el.products).map(el => el.register);
            return prod;
        },
    },
    methods: {
        async apply() {
            this.$emit('onApply', { ...this.filters });
        },
        onFilterApply() {
            return true;
        },
        onFilterClear() {
            this.filters = {};
            this.$emit('onApply', { ...this.filters });
            this.emissao = null;
            this.emissaoRange = null;
            this.emissao_fim = null;
            this.vencimento_fim = null;
            this.vencimentoRange = null;
            this.venc;
        },
        formatDateRange(range, field) {
            return formatDateRangeSetKeys(range, field, this, ['_inicio', '_fim']);
        },
    },
};
</script>
