<template>
    <v-main>
        <v-form ref="form" class="ml-3 mr-3">
            <v-row>
                <!--
                <v-col cols="12" sm="12" md="3" lg="3">
                    <v-text-field
                        color="secondary"
                        v-model="filters.lastro"
                        label="Lastro"
                        class="v-input-filter"
                    >
                    </v-text-field>
                </v-col>
                -->
                <v-col cols="12" sm="12" md="6">
                    <v-menu
                        ref="menuVencimento"
                        v-model="menuVencimento"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="vencimentoRange"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                class="v-input-default"
                                color="secondary"
                                v-model="vencimentoRange"
                                label="Data (Inicio/fim) vencimento"
                                prepend-icon="event"
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-if="menuVencimento"
                            v-model="dateVencimento"
                            no-title
                            scrollable
                            range
                            locale="pt-br"
                        >
                            <v-spacer></v-spacer>
                            <v-btn depressed color="secondary" @click="menuVencimento = false"
                                >Cancelar</v-btn
                            >
                            <v-btn
                                color="secondary"
                                depressed
                                @click="
                                    $refs.menuVencimento.save(
                                        formatDateRange(dateVencimento, 'vencimento')
                                    )
                                "
                                >Salvar</v-btn
                            >
                        </v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="mt-3 container-filters-buttons">
                    <v-btn
                        depressed
                        @click="apply"
                        color="secondary"
                        class="v-btn-padded v-btn-responsive"
                    >
                        Aplicar
                    </v-btn>

                    <v-btn
                        outlined
                        @click="onFilterClear"
                        depressed
                        color="secondary"
                        class="v-btn-padded v-btn-responsive"
                    >
                        Limpar
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-main>
</template>

<script>
import { formatDateRangeSetKeys } from '@/helpers/data';
export default {
    props: {},
    data: () => ({
        filters: {},
        menuVencimento: false,
        dateVencimento: null,
        vencimentoRange: null,
    }),

    computed: {},
    methods: {
        apply() {
            this.$emit('onApply', { ...this.filters });
        },
        onFilterClear() {
            this.filters = {};
            this.vencimentoRange = null;
            this.$emit('onApply', { ...this.filters });
        },
        formatDateRange(range, field) {
            return formatDateRangeSetKeys(range, field, this);
        },
    },
};
</script>
