import axios from '@/axios';
import config from '@/helpers/config';

const listRegisters = (idProduct, filters) =>
    axios.get(`/v2/${idProduct}/documentos/solicitacoes?${filters}`);

const getManufacturers = (idProduct, filters) =>
    axios.get(`/v2/cadastro/${idProduct}/produtores/listagem?${filters}`);

const getManufacturer = (idProduct, id) => axios.get(`/v2/cadastro/${idProduct}/produtor/${id}`);

const updateRegistryGeneral = (idProduct, id, payload) =>
    axios.patch(`/v2/cadastro/${idProduct}/informacoes/gerais/${id}`, payload);

const updateRegistryConfig = (idProduct, id, payload) =>
    axios.patch(`/v2/cadastro/${idProduct}/operacoes/${id}`, payload);

const exportManufacturers = (idProduct, filters) =>
    axios.get(`/v2/cadastro/${idProduct}/produtores/export?${filters}`, {
        responseType: 'blob',
    });

const fileTypesList = idProduct => axios.get(`/v2/${idProduct}/tipo_arquivo/`);

const uploadPendingDocuments = (idProduct, formData, idSolicitacao) => {
    return axios.post(
        `/v2/${idProduct}/solicitacoes/upload?idSolicitacao=${idSolicitacao}`,
        formData
    );
};

const listStates = () => axios.get(`/v2/enums/estados`);

const users = (idProduct, id) => axios.get(`/v2/${idProduct}/cadastro/tipo/${id}`);

const createUser = (payload) => axios.post(`${config.apiUsersUrl}/api/v1/users/`, payload);

const updateUser = (id, payload) => axios.put(`${config.apiUsersUrl}/api/v1/users/${id}`, payload);

const getUser = (id) => axios.get(`${config.apiUsersUrl}/api/v1/users/${id}`);

const listSegments = id => axios.get(`/v2/cadastro/produtos/${id}/segmentos`);

export const getUsers = async filters => axios.get(`${config.apiUsersUrl}/api/v1/users?${filters}`);

export const resendToken = async id =>  axios.get(`${config.apiUsersUrl}/api/v1/users/${id}/token`);

export {
    listRegisters,
    uploadPendingDocuments,
    fileTypesList,
    getManufacturers,
    listStates,
    users,
    exportManufacturers,
    updateRegistryGeneral,
    listSegments,
    updateRegistryConfig,
    getManufacturer,
    createUser,
    updateUser,
    getUser
};
