export default [
    {
        label: 'Gestão de Títulos',
        areas: [
            {
                label: 'Originação',
                icon: 'mdi-wallet-outline',
                links: [
                    { to: '/admin/originacao/nova/selecao', label: 'Nova Originação' },
                    { to: '/admin/gestao/titulos/lista', label: 'Desembolsos' },
                ],
            },
            {
                label: 'Factoring',
                icon: 'mdi-factory',
                links: [
                    { to: '/admin/originacao/factoring/selecao', label: 'Novo Factoring' },
                    { to: '/admin/gestao/factoring/lista', label: 'Remessas' },
                ],
            },
            {
                label: 'Importações',
                icon: 'mdi-cloud-upload-outline',
                links: [{ to: '/admin/importacoes/carga_ar', label: 'Carga AR' }],
            },
        ],
    },
    {
        label: 'Meus clientes',
        areas: [
            {
                label: 'Crédito',
                icon: 'mdi-credit-card-outline',
                links: [
                    { to: '/admin/credito/limites/solicitar', label: 'Solicitação Individual' },
                    { to: '/admin/credito/limites/solicitar_lote', label: 'Solicitação em Lote' },
                    { to: '/admin/credito/limites/consultar', label: 'Consulta' },
                ],
            },
            {
                label: 'Cadastros',
                icon: 'mdi-folder-multiple-outline',
                links: [
                    {
                        to: '/admin/cadastros/produtores/pendencias',
                        label: 'Pendências',
                    },
                    { to: '/admin/cadastros/produtores', label: 'Produtores' },
                ],
            },

            {
                label: 'Perfil de acesso',
                icon: 'mdi-account-circle-outline',
                links: [
                    {
                        to: '/admin/cadastros/usuarios/pendencias',
                        label: 'Pendências',
                    },
                    { to: '/admin/cadastros/usuarios', label: 'Perfil de acesso' },
                ],
            },

        ],
    },
];
