import { gettersBuilder } from '@/helpers/store';
import requestStatusKeys from './_requestStatusKeys';
import basicKeys from './_basicKeys';

export default {
    statesEnumAsArray: state => {
        if (!state.statesList) {
            return [];
        }
        return Object.values({ ...state.statesList }).sort((prev, next) => {
            return prev > next ? 1 : -1;
        });
    },
    ...gettersBuilder(requestStatusKeys),
    ...gettersBuilder(basicKeys),
};
