<template>
    <v-main>
        <div class="pt-9 pr-9 pl-9 pb-main-default">
            <v-container>
                <v-row class="mb-9">
                    <Tabs
                        ref="tabEl"
                        @update="updateTab"
                        :tabs="tabs"
                        :allowUserChange="true"
                        :showCounter="false"
                    />
                </v-row>

                <v-row class="mt-13" v-if="currentTab === 'consultar'">
                    <Consultar />
                </v-row>
                <v-row class="mt-13" v-if="currentTab === 'pendencias'">
                    <Pending />
                </v-row>
            </v-container>
        </div>
    </v-main>
</template>
<script>
import Tabs from '@/components/base/Tabs';
import { RequestStatusEnum } from '@/helpers/consts';

import Pending from './produtores/_Pending';
import Consultar from './produtores/Consultar';

export default {
    components: {
        Tabs,
        Consultar,
        Pending,
    },
    data() {
        return {
            tabs: [
                {
                    name: this.$tc('defaults.tabs.consult'),
                    path: 'consultar',
                },
                {
                    name: this.$tc('defaults.tabs.pending'),
                    path: 'pendencias',
                },
            ],
            currentTab: null,
            RequestStatusEnum,
        };
    },
    computed: {
        tabFromQuery() {
            return this.$router.history.current.params.step;
        },
    },
    methods: {
        updateTab(item) {
            this.$refs.tabEl.updateTabRouting(this, item, '/admin/cadastros/produtores');
        },
        updateTabWithouRoute(tab) {
            this.currentTab = tab;
            const index = this.tabs.findIndex(tab => this.currentTab === tab.path);
            if (this.$refs.tabEl.toIndex) {
                this.$refs.tabEl.toIndex(index);
            }
        },
    },
    mounted() {
        this.updateTabWithouRoute(this.tabFromQuery);
    },
    watch: {
        '$route.path': function () {
            this.updateTabWithouRoute(this.$router.history.current.params.step);
        },
    },
};
</script>
