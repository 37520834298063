import router from '../../router/router';
import localStorageObject from '@/helpers/localStorageObject';

const JWT = 'Token';
const SelectedProduct = 'SelectedProduct';
const init = localStorage[JWT];
const initProduct = localStorageObject.getItem(SelectedProduct);

const plugin = store => {
    store.subscribe((mutation, state) => {
        switch (mutation.type) {
            case 'auth/setToken':
                if (state.auth.permanecerConectado) {
                    localStorageObject.setItem(JWT, mutation.payload);
                    router.push('/produto');
                }
                break;
            case 'auth/resetToken':
                localStorageObject.clearKey(JWT);
                localStorageObject.clearKey(SelectedProduct);
                store.dispatch('base/updateCurrentProduct', null);
                break;
            case 'base/setSelectedProduct':
                localStorageObject.setItem(SelectedProduct, mutation.payload);
                break;
        }
    });
};

export default plugin;
export { init, initProduct };
