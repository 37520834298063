import { RequestStatusEnum, newErrorBuilder } from '@/helpers/consts';
import { currencyUnmask } from '@/helpers/masks';
import limitFromAPI from '@/helpers/dtos/limitFromAPI';
import limitDetailFromAPI from '@/helpers/dtos/limitDetailFromAPI';
import managerFromAPI from '@/helpers/dtos/managerFromAPI';
import registerFromAPI from '@/helpers/dtos/registerFromAPI';
import limitBatchFromAPI from '@/helpers/dtos/limitBatchFromAPI';
import registerToAPI from '@/helpers/dtos/registerToAPI';
import queryString from '@/helpers/dtos/queryString';
import { _fetchDefaultParserPagination, _dismissDefaultParserState } from '@/helpers/store';

import {
    createRegister,
    getStatusList,
    getLimit as getLimitService,
    perRoot,
    getClientData,
    uploadExcel,
    listLimits as listLimitsService,
    listLimitsDownload,
    requestPerValue,
    managers,
    listSegments,
    findRegistersByDocument,
    listStates,
} from '../service';

export default {
    async listClientsByGroup({ commit }, params) {
        commit('setListClientsByGroupStatus', RequestStatusEnum.START);
        try {
            const { groupId } = params;
            const data = await getClientData(groupId);
            const totalPages = data.data.totalPages;
            commit('setListClientsByGroup', { result: data.data, totalPages });
            commit('setListClientsByGroupStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setListClientsByGroupStatus', newErrorBuilder(error));
        }
    },

    async uploadExcelRequest({ commit }, { idProduct, formData }) {
        try {
            commit('setUploadExcelRequestStatus', RequestStatusEnum.START);
            await uploadExcel(idProduct, formData);
            commit('setUploadExcelRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setUploadExcelRequestStatus', newErrorBuilder(error));
        }
    },

    async dismissExcelRequestStatus({ commit }) {
        commit('setUploadExcelRequestStatus', RequestStatusEnum.IDLE);
    },

    async listLimitsPerBatch({ commit }, { id_produto, tipo, filters }) {
        commit('setLimitsPerBatchRequestStatus', RequestStatusEnum.START);
        try {
            const params = queryString(filters, searchKeysMapping);
            const { data } = await listLimitsService(id_produto, tipo, params);
            const result = data.data.content.map(el => limitBatchFromAPI(el));
            const totalPages = data.data.totalPages;
            commit('setLimitsPerBatchRequestStatus', RequestStatusEnum.SUCCESS);
            commit('setLimitsPerBatch', { result, totalPages });
        } catch (error) {
            commit('setLimitsPerBatchRequestStatus', newErrorBuilder(error));
        }
    },

    async listLimits({ commit }, { id_produto, tipo, filters }) {
        commit('setLimitsRequestStatus', RequestStatusEnum.START);

        try {
            const params = queryString(filters, searchKeysMapping);
            const { data } = await listLimitsService(id_produto, tipo, params);
            const result = data.data.content.map(el => limitBatchFromAPI(el));
            const totalPages = data.data.totalPages;
            commit('setLimits', { result, totalPages });
            commit('setLimitsRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setLimitsRequestStatus', newErrorBuilder(error));
        }
    },
    async downloadLimits({ commit }, { id_produto, tipo, filters }) {
        commit('setDownloadLimitsRequestStatus', RequestStatusEnum.START);
        try {
            const params = queryString(filters, searchKeysMapping);
            const data = await listLimitsDownload(id_produto, tipo, params);
            commit('setDownloadLimitsResult', {
                file: data.data,
            });
            commit('setDownloadLimitsRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setDownloadLimitsRequestStatus', newErrorBuilder(error));
        }
    },
    async listStatusList({ commit }, { id_produto }) {
        try {
            const { data } = await getStatusList(id_produto);
            commit('setStatusList', data.data);
        } catch (error) {
            console.error(error);
        }
    },
    async getLimit({ commit }, { id_produto, id }) {
        commit('setLimitRequestStatus', RequestStatusEnum.START);
        try {
            const { data } = await getLimitService(id_produto, id);
            const el = data.data;
            const res = limitDetailFromAPI(el);
            commit('setLimit', res);
            commit('setLimitRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setLimitRequestStatus', newErrorBuilder(error));
        }
    },

    resetListLimits({ commit }) {
        commit('setLimits', { result: [], totalPages: null });
        commit('setLimitsRequestStatus', RequestStatusEnum.IDLE);
    },

    resetLimit({ commit }) {
        commit('setLimit', null);
        commit('setLimitRequestStatus', RequestStatusEnum.IDLE);
    },

    async request({ commit }, { productId, data, value, comments }) {
        try {
            commit('setRequestRequestStatus', RequestStatusEnum.START);
            await requestPerValue(productId, currencyUnmask(value), {
                documento: data.document,
                comentarios_originador: comments,
            });
            commit('setRequestRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setRequestRequestStatus', newErrorBuilder(error));
        }
    },

    dismissRequestRequestStatus({ commit }) {
        commit('setRequestRequestStatus', RequestStatusEnum.IDLE);
    },

    async perRoot({ commit }, { idProduct, identifier }) {
        try {
            commit('setLimitsPerRootStatus', RequestStatusEnum.START);
            const { data } = await perRoot(idProduct, identifier);

            const limits = data.data.map(item => limitFromAPI(item));

            commit('setLimitsPerRootResult', limits);
            commit('setLimitsPerRootStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setLimitsPerRootResult', null);
            commit('setLimitsPerRootStatus', newErrorBuilder(error));
        }
    },

    async submitRegister({ commit }, { id, data }) {
        const registerDTO = registerToAPI(data);
        try {
            commit('setCreateRegisterStatus', RequestStatusEnum.START);
            await createRegister(id, registerDTO);
            commit('setCreateRegisterStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setCreateRegisterStatus', newErrorBuilder(error));
        }
    },

    async listManagers({ commit }, { id }) {
        try {
            const { data } = await managers(id);
            const arr = data.data.map(item => managerFromAPI(item));
            commit('setManagersList', arr);
        } catch (error) {
            commit('setManagersList', []);
        }
    },

    async getSegments({ commit }, { id }) {
        try {
            const { data } = await listSegments(id);
            commit('setSegments', data.data);
        } catch (error) {
            console.error(error);
        }
    },

    async findByDocument({ commit }, key) {
        commit('setFindByDocumentStatus', RequestStatusEnum.START);

        try {
            const response = await findRegistersByDocument(key);

            if (!response) {
                throw new Error('Unexpected error');
            }
            const register = registerFromAPI(response.data.data);
            commit('setFindByDocumentStatus', RequestStatusEnum.SUCCESS);
            commit('setFindByDocumentResult', register);
        } catch (error) {
            commit('setFindByDocumentStatus', newErrorBuilder(error));
        }
    },

    dismissAllFindByDocument({ commit }) {
        commit('setFindByDocumentResult', null);
        commit('setFindByDocumentStatus', RequestStatusEnum.IDLE);
    },

    dismissFindByDocumentStatus({ commit }) {
        commit('setFindByDocumentStatus', RequestStatusEnum.IDLE);
    },

    async fetchStates({ commit }) {
        const { data } = await listStates();
        commit('setStates', data.data);
    },

    async fetchLimitsFromClient({ commit }, { id_produto, tipo, filters }) {
        commit('setLimitsFromClientListRequestStatus', RequestStatusEnum.START);

        try {
            const params = queryString(filters, searchKeysMapping);
            const { data } = await listLimitsService(id_produto, tipo, params);
            _fetchDefaultParserPagination(commit, data, limitBatchFromAPI, 'LimitsFromClientList');
        } catch (error) {
            commit('setLimitsFromClientListRequestStatus', newErrorBuilder(error));
        }
    },

    resetLimitsFromClient({ commit }) {
        _dismissDefaultParserState(commit, 'LimitsFromClientList');
    },

    updateBaseData({ commit }, { selectedProduct, originators }) {
        commit('setBaseData', { selectedProduct, originators });
    },
};

const searchKeysMapping = {
    states: 'estado',
    manager: 'gerente',
};
