import { RequestStatusEnum, newErrorBuilder } from '@/helpers/consts';
import queryString from '@/helpers/dtos/queryString';
import {
    getUsers,
    createUser as createUserService,
    updateUser as updateUserService,
    getUser,
    resendToken,
} from '../service';

export default {
    async fetchUsers({ commit }, { filters }) {
        commit('setUsersListRequestStatus', RequestStatusEnum.START);
        try {
            const params = queryString(filters, {});
            const { data } = await getUsers(params);
            commit('setUsersListResult', data);
            commit('setUsersListRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setUsersListRequestStatus', newErrorBuilder(error));
        }
    },
    async fetchPendingUsers({ commit }, { filters }) {
        commit('setPendingUsersListRequestStatus', RequestStatusEnum.START);
        try {
            const params = queryString({ ...filters, ['active']: false }, {});
            const { data } = await getUsers(params);
            commit('setPendingUsersListResult', data);
            commit('setPendingUsersListRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setPendingUsersListRequestStatus', newErrorBuilder(error));
        }
    },
    async fetchUser({ commit }, { id }) {
        commit('setUserRequestStatus', RequestStatusEnum.START);
        try {
            const { data } = await getUser(id);
            commit('setUserDetail', data);
            commit('setUserRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setUserRequestStatus', newErrorBuilder(error));
        }
    },
    async createUser({ commit }, params) {
        commit('setCreateUserRequestStatus', RequestStatusEnum.START);
        try {
            await createUserService(params);
            commit('setCreateUserRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            console.log(error);
            commit('setCreateUserRequestStatus', newErrorBuilder(error));
        }
    },
    async updateUser({ commit }, { userId, ...params }) {
        commit('setUpdateUserRequestStatus', RequestStatusEnum.START);
        try {
            await updateUserService(userId, params);
            commit('setUpdateUserRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setUpdateUserRequestStatus', newErrorBuilder(error));
        }
    },

    async askForNewToken({ commit }, { userId }) {
        commit('setAskForNewTokenRequestStatus', RequestStatusEnum.START);
        try {
            await resendToken(userId);
            commit('setAskForNewTokenRequestStatus', RequestStatusEnum.SUCCESS);
        } catch (error) {
            commit('setAskForNewTokenRequestStatus', newErrorBuilder(error));
        }
    },
};
