<template>
    <v-main>
        <div class="pt-11 pb-14 pr-9 pl-9">
            <v-container v-if="originacaoRequestStatus === RequestStatusEnum.SUCCESS">
                <v-row>
                    <Tabs
                        :showCounter="true"
                        ref="tabEl"
                        :tabs="tabs"
                        :initialSelect="1"
                        :allowUserChange="false"
                    />
                    <v-row justify="end">
                        <v-col cols="12" xl="4" lg="4" md="6" sm="6" class="mt-8 mt-md-6 mb-4 mr-md-6 mr-0 pl-6 pr-6 pr-md-0 pl-md-0">
                            <div
                                class="
                                    secondary
                                    pa-2
                                    font-size
                                    text-no-wrap
                                    rounded-pill
                                    d-flex
                                    align-center
                                "
                            >
                                <v-img
                                    max-height="30"
                                    max-width="30"
                                    src="@/assets/iconFarm/icon-Notas-Selecionadas.svg"
                                    alt="Notas selecionadas"
                                    class="mr-8"
                                ></v-img>
                                <span class="white--text">
                                    {{ $tc('origination.selectedNFs', totalNFsCount) }}
                                </span>
                            </div>
                        </v-col>
                    </v-row>

                    <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <div
                            class="pending pa-2 text-no-wrap rounded-pill font-size"
                            v-if="originacao.totalNotasInelegiveis > 0"
                        >
                            <v-row class="text-wrap">
                                <v-col xl="4" lg="4" md="4" sm="4">
                                    <div class="d-flex font-size align-center">
                                        <v-icon color="red" class="ml-3" style="opacity: 0.5">
                                            mdi-alert
                                        </v-icon>
                                        <span style="opacity: 0.5" class="red--text ml-3"
                                            >{{ originacao.totalNotasInelegiveis }} Notas
                                            inelegiveis para desembolso</span
                                        >
                                    </div>
                                </v-col>
                                <v-col xl="5" lg="5" md="2" sm="2"></v-col>
                                <v-col
                                    xl="3"
                                    lg="3"
                                    md="6"
                                    sm="6"
                                    class="
                                        d-flex
                                        justify-xl-end justify-md-end justify-sm-end justify-center
                                        align-center
                                    "
                                >
                                    <v-btn
                                        danger
                                        outlined
                                        class="text-lowercase mr-4"
                                        @click="onExport"
                                        small
                                    >
                                        <v-icon color="red" right class="mr-1">
                                            mdi-arrow-collapse-down </v-icon
                                        ><span class="red--text text-capitalize"
                                            >Exportar</span
                                        ></v-btn
                                    >
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                    <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="mb-4 mt-n2">
                        <div style="background: #e1f6f2" class="pa-2 rounded-pill font-size">
                            <div class="d-flex align-center flex-wrap">
                                <v-icon color="#00b493" class="ml-2">mdi-check-all</v-icon>
                                <span
                                    v-if="originacao.totalNotasInelegiveis === 0"
                                    style="color: #00b493"
                                    class="ml-3 ml-xs-1"
                                >
                                    Você ainda pode prosseguir com as seguintes notas
                                </span>
                                <span v-else style="color: #00b493" class="ml-3 ml-xs-1">
                                    Notas processadas com sucesso
                                </span>
                            </div>
                        </div>
                    </v-col>

                    <v-col xl="12" lg="12" md="12" sm="12" class="pa-3">
                        <v-expansion-panels flat class="flat-container" v-if="originacao">
                            <v-expansion-panel
                                v-for="item in originacao.content"
                                :key="item.idCliente"
                            >
                                <v-expansion-panel-header class="font-size">
                                    {{ item.nomeCliente }}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table
                                        :headers="headersRevisao"
                                        :items="item.titulos"
                                        hide-default-footer
                                        class="border-table pa-4 mt-6"
                                    >
                                        <template v-slot:[`item.tipo`]="{ item }">
                                            <v-chip
                                                :color="getColor(item.tipo)"
                                                style="font-size: 12px; width: 80px"
                                            >
                                                <v-icon :color="`${color}`" class="mr-1">{{
                                                    icone
                                                }}</v-icon>
                                                <span :style="`color: ${color}`">
                                                    {{ item.tipo }}</span
                                                >
                                            </v-chip>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>

                    <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <div>
                            <span class="mb-2 font-size">Aviso</span>
                            <div class="pa-3 border-table font-size">
                                {{ $tc(`${routeType}.revision.warning`) }}
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <div class="mr-4">
                            <v-checkbox
                                v-model="checkbox"
                                :label="$tc('defaults.form.aware')"
                            ></v-checkbox>
                        </div>
                    </v-col>
                    <v-col cols="12" align="right">
                        <v-btn
                            depressed
                            class="mr-6 v-btn-responsive"
                            @click="onClickBack"
                            color="secondary"
                        >
                            {{ $tc('defaults.form.previous') }}
                        </v-btn>
                        <v-btn
                            depressed
                            class="v-btn-responsive mt-6 mt-sm-0"
                            color="secondary"
                            :disabled="!checkbox"
                            @click="confirmarRemessa"
                        >
                            {{ $tc(`${routeType}.revision.confirm`) }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
            <Loader
                v-if="originacaoRequestStatus === RequestStatusEnum.START"
                align="center"
                justify="center"
            />
        </div>
    </v-main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import Tabs from '@/components/base/Tabs';
import fileSaver from '@/helpers/fileSaver';
import { RequestStatusEnum } from '@/helpers/consts';
import { notification } from '@/helpers/notification';
import Loader from '@/components/base/Loader';
import { tabSelecao } from './consts';

export default {
    components: {
        Tabs,
        Loader,
    },
    data: () => ({
        RequestStatusEnum,
        tabs: tabSelecao,
        icone: '',
        color: '',
        checkbox: false,
        headersRevisao: [
            {
                text: 'Tipo',
                align: 'center',
                sortable: false,
                value: 'tipo',
                width: '5rem',
            },
            { text: 'Fornecedor', align: 'start', value: 'fornecedor', width: '15rem', sortable: false, },
            { text: 'Valor Nominal', align: 'start', value: 'valorNominal', width: '6rem', sortable: false, },
            { text: 'Vencimentos', align: 'start', value: 'vencimentos', width: '6rem', sortable: false, },
        ],
    }),
    computed: {
        ...mapGetters('originacao', {
            originacao: 'originacao',
            selectedProduct: 'selectedProduct',
            exportOriginacaoRequestStatus: 'exportOriginacaoRequestStatus',
            confirmOriginacaoRequestStatus: 'confirmOriginacaoRequestStatus',
            exportOriginacaoData: 'exportOriginacao',
            originacaoRequestStatus: 'originacaoRequestStatus',
        }),
        id_originacao() {
            return this.$route.params.id;
        },
        totalNFsCount() {
            return this.originacao.totalNotasInelegiveis + this.originacao.totalNotasElegiveis;
        },
        routeType() {
            return this.$route.meta.type;
        },
        routeBack() {
            return this.routeType === 'origination'
                ? '/admin/originacao/nova/selecao'
                : '/admin/originacao/factoring/selecao';
        },
        routeConfirm() {
            return this.routeType === 'origination'
                ? '/admin/gestao/titulos/lista'
                : '/admin/gestao/factoring/lista';
        },
    },
    watch: {
        exportOriginacaoRequestStatus(newValue) {
            if (newValue === RequestStatusEnum.SUCCESS) {
                const fileName = 'gestao_titulos.xlsx';
                return fileSaver(
                    this.exportOriginacaoData.file,
                    this.exportOriginacaoData.file.type,
                    fileName
                );
            }
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    this.$t('origination.revision.exportOriginacaoRequestStatus.error', {
                        msg: newValue.message,
                    })
                );
                return RequestStatusEnum.ERROR;
            }
        },
        confirmOriginacaoRequestStatus(newValue) {
            if (newValue === RequestStatusEnum.SUCCESS) {
                notification(this.$dialog.notify.success, 'Remessa confirmada');
                this.$router.push(this.routeConfirm);
            }
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    this.$t('origination.revision.removeNFsByIdAndClientRequestStatus.error', {
                        msg: newValue.message,
                    })
                );
                return RequestStatusEnum.ERROR;
            }
        },
        originacaoRequestStatus(newValue) {
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    this.$t('origination.revision.removeNFsByIdAndClientRequestStatus.error', {
                        msg: newValue.message,
                    })
                );
                return RequestStatusEnum.ERROR;
            }
        },
    },
    methods: {
        ...mapActions('originacao', {
            fetchOriginacao: 'fetchOriginacao',
            confirmOriginacao: 'confirmOriginacao',
            exportOriginacao: 'exportOriginacao',
        }),
        getColor(tipo) {
            if (tipo === 'CPRF') {
                this.icone = 'mdi-file-table-outline';
                this.color = '#003366';
                return '#E5EAEF';
            } else if (tipo === 'CDCA') {
                this.icone = 'mdi-file-document-outline';
                this.color = '#1BB393';

                return '#E5F7F4';
            }
        },
        onExport() {
            this.exportOriginacao({
                id_produto: this.selectedProduct.id,
                id: this.id_originacao,
            });
        },
        async confirmarRemessa() {
            await this.confirmOriginacao({
                id_produto: this.selectedProduct.id,
                id: this.id_originacao,
            });
        },
        async onClickBack() {
            await this.$dialog.confirm({
                text: this.$tc(`${this.routeType}.dialog.confirmLeave`),
                title: this.$tc('defaults.dialog.leave'),
                actions: {
                    false: {
                        text: 'Cancelar',
                        color: 'danger',
                    },
                    true: {
                        text: 'Sim',
                        color: 'secondary',
                        handle: () => {
                            this.$router.push(this.routeBack);
                        },
                    },
                },
            });
        },
    },
    async created() {
        await this.fetchOriginacao({
            id_produto: this.selectedProduct.id,
            id: this.id_originacao,
        });
    },
};
</script>
<style scoped>
.font-size {
    font-size: 13px;
}
.border-table {
    border: solid 1px #f0f0f0;
}
</style>
