import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import storage from '@/helpers/localStorageObject';
import VueGtag from 'vue-gtag';
import config from '@/helpers/config';

import _middlewares from './middlewares';

const { checkAuth } = _middlewares(storage);

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(checkAuth);

Vue.use(VueGtag, {
    config: { id: config.analyticsId },
}, router);

export default router;
