<template>
    <v-main>
        <v-row justify="space-between">
            <v-col md="6" sm="12" cols="12" lg="6">
                <MainFilter
                    @onInputChange="filterInputChanged"
                    @onClick="showFilters"
                    :hasExtraFilters="false"
                />
            </v-col>
            <!--
            <v-col md="6" sm="12" cols="12" lg="6" align="right">
                <ExportButton @onClick="onExport" :status="exportPendingRegistersStatus" />
            </v-col>
            -->
        </v-row>

        <v-row v-show="filter" class="mb-9">
            <Filters :loadFilters="false" @onApply="searchListener" />
        </v-row>

        <Table
            class="mt-6"
            :items="items"
            :pages="totalPages"
            :currentPage="page"
            :headers="headers"
            :requestStatus="pendingRegistersRequestStatus"
            @onPager="onPager"
            @onDocumentBtn="onDocumentBtn"
        />

        <Loader
            class="mt-9"
            align="center"
            v-if="pendingRegistersRequestStatus === RequestStatusEnum.START"
        />

        <v-dialog content-class="modal-default" v-model="dialog">
            <header>
                Enviar documentos
                <v-icon role="button" title="Fechar" @click="closeModal" color="altPrimary"
                    >mdi-close-thick</v-icon
                >
            </header>

            <v-main>
                <section>
                    <v-form>
                        <v-row
                            v-show="
                                uploadPendingDocumentsRequestStatus === RequestStatusEnum.IDLE &&
                                pendingDocumentsListRegistersRequestStatus ===
                                    RequestStatusEnum.SUCCESS
                            "
                            v-if="filesRequiredToUpload.length > 0"
                            class="mt-9"
                        >
                            <v-col
                                v-for="file in filesRequiredToUpload"
                                v-bind:key="file.id"
                                cols="12"
                                sm="12"
                                md="6"
                            >
                                <h4>{{ file.descricao }}</h4>
                                <v-main class="mt-3">
                                    <FilePicker
                                        ref="filePicker"
                                        @onReset="resetted(file.id)"
                                        @onFileChange="_file => fileChanged(_file, file.id)"
                                        :acceptedFileTypes="'image/*'"
                                    />
                                </v-main>
                            </v-col>
                        </v-row>

                        <Loader
                            class="mt-9"
                            align="center"
                            v-if="
                                uploadPendingDocumentsRequestStatus === RequestStatusEnum.START ||
                                pendingDocumentsListRegistersRequestStatus ===
                                    RequestStatusEnum.START
                            "
                        />

                        <footer align="right" class="mt-6">
                            <v-btn
                                :disabled="
                                    !isValid ||
                                    uploadPendingDocumentsRequestStatus === RequestStatusEnum.START
                                "
                                @click="uploadFiles"
                                color="secondary"
                                depressed
                            >
                                {{ $tc('defaults.form.confirm') }}
                            </v-btn>
                        </footer>
                    </v-form>
                </section>
            </v-main>
        </v-dialog>
    </v-main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import MainFilter from '@/components/base/mainfilter/MainFilter';
import FilePicker from '@/components/base/FilePicker/FilePicker';
import Filters from '../_Filters';
import Table from '../_Table';
import { RequestStatusEnum } from '@/helpers/consts';
import { notification } from '@/helpers/notification';
import fileSaver from '@/helpers/fileSaver';
import { pendingHeaders as headers } from '../_headers';
import Loader from '@/components/base/Loader';

export default {
    components: {
        MainFilter,
        FilePicker,
        Filters,
        Table,
        Loader,
    },
    data() {
        return {
            filter: false,
            dialog: false,
            balance: null,
            irpf: null,
            statement: null,
            isValid: false,
            filters: {
                page: 0,
                limit: 10,
            },
            lastSearchFilters: null,
            items: [],
            totalPages: null,
            headers,
            page: 1,
            RequestStatusEnum,
            selectedRegister: {},
            pendingFilesToUpload: [],
            selectedFiles: {},
            filePicker: null,
        };
    },
    methods: {
        ...mapActions('cadastros', {
            exportPendingRegisters: 'exportPendingRegisters',
            fetchPendingRegisters: 'fetchPendingRegisters',
            uploadDocuments: 'uploadPendingDocumentsRequest',
            dismissUpload: 'dismissUpload',
            uploadPendingDocumentsRequest: 'uploadPendingDocumentsRequest',
            fetchPendingDocuments: 'fetchPendingDocuments',
            dismissPendingDocuments: 'dismissPendingDocuments',
        }),
        closeModal() {
            this.dialog = false;
            this.reset();
        },
        fileChanged(file, type) {
            this.selectedFiles[type] = file;
            this.checkValidity();
        },
        resetted(type) {
            this.selectedFiles[type] = null;
            this.checkValidity();
        },
        reset() {
            this.dismissUpload();
            if (this.$refs.filePicker) {
                this.$refs.filePicker.forEach(el => {
                    el.reset();
                });
            }

            this.selectedFiles = {};
        },
        uploadFiles() {
            this.uploadDocuments({
                idProduct: this.selectedProduct.id,
                items: this.pendingDocumentsList.map(pd => {
                    return {
                        file: this.selectedFiles[pd.idTipoArquivo],
                        idSolicitacao: pd.idSolicitacao,
                    };
                }),
            });
        },
        checkValidity() {
            this.isValid =
                this.pendingDocumentsList.length === Object.keys(this.selectedFiles).length;
            return this.isValid;
        },
        filterInputChanged(value) {
            this.filters.nomeOuDoc = value;
            if (value.length >= 3 || value === '') {
                this.searchRegisters();
            }
        },
        searchRegisters() {
            if (!this.filters.nomeOuDoc) {
                delete this.filters.nomeOuDoc;
            }
            this.lastSearchFilters = { ...this.filters };
            this.fetchPendingRegisters({
                idProduct: this.selectedProduct.id,
                filters: { ...this.filters },
            });
        },
        showFilters() {
            this.filter = !this.filter;
        },
        searchListener(data) {
            const nomeOuDoc = this.filters.nomeOuDoc || '';
            this.filters = { ...data, page: 0, limit: 10 };
            this.filters.nomeOuDoc = nomeOuDoc;
            this.searchRegisters();
        },
        onExport() {
            this.exportPendingRegisters({ ...this.lastSearchFilters });
        },
        onDocumentBtn(item) {
            this.selectedRegister = item;
            const filters = { nomeOuDoc: item.documento, limit: 100 };
            this.isValid = false;
            this.fetchPendingDocuments({ idProduct: this.selectedProduct.id, filters });
            this.dialog = true;
        },
        onPager(page) {
            this.page = page;
            const filters = { ...this.lastSearchFilters };
            filters.page = page - 1;
            this.fetchPendingRegisters({
                idProduct: this.selectedProduct.id,
                filters: { ...filters },
            });
        },
    },
    mounted() {
        if (this.selectedProduct) this.searchRegisters();
    },
    watch: {
        uploadPendingDocumentsRequestStatus(newValue) {
            if (newValue === RequestStatusEnum.SUCCESS) {
                notification(
                    this.$dialog.notify.success,
                    'Documentos enviados com sucesso sucesso'
                );

                this.closeModal();
                this.reset();
                this.dismissPendingDocuments();
                this.searchRegisters();
                return RequestStatusEnum.SUCCESS;
            }
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    'Ocorreu um erro ao enviar os arquivos: ' + newValue.message
                );
                return RequestStatusEnum.ERROR;
            }
        },
        report(newValue) {
            return fileSaver(newValue.data, 'application/vnd.ms-excel', 'cadastros_pendentes.csv');
        },
        pendingRegistersResult(newValue) {
            this.items = newValue.items;
            this.totalPages = newValue.totalPages;
        },
    },
    computed: {
        ...mapGetters('cadastros', {
            exportPendingRegistersStatus: 'exportPendingRegistersStatus',
            uploadPendingDocumentsRequestStatus: 'uploadPendingDocumentsRequestStatus',
            pendingRegistersRequestStatus: 'pendingRegistersRequestStatus',
            pendingRegistersResult: 'pendingRegistersResult',
            selectedProduct: 'selectedProduct',
            fileTypesList: 'fileTypesList',
            pendingDocumentsList: 'pendingDocumentsList',
            pendingDocumentsListRegistersRequestStatus:
                'pendingDocumentsListRegistersRequestStatus',
        }),
        filesRequiredToUpload() {
            const files = this.fileTypesList.filter(
                item =>
                    this.pendingDocumentsList.map(item => item.idTipoArquivo).indexOf(item.id) >= 0
            );

            return files;
        },
    },
};
</script>
<style scoped>
section#pending-container {
    width: 100%;
}
</style>
