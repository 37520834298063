<template>
    <v-main>
        <v-row justify="space-between">
            <v-col md="6" sm="12" cols="12" lg="6">
                <MainFilter @onInputChange="filterInputChanged" @onClick="toggleFilter" />
            </v-col>
        </v-row>

        <v-row v-show="showFilters" class="mb-12">
            <Filters @onApply="searchListener" />
        </v-row>
        <div class="mt-6">
            <v-data-table
                :headers="headers"
                :items="followUpListResult.items"
                hide-default-footer
                class="elevation-1 pb-4"
            >
                <template slot="no-data">
                    <div>Não há dados</div>
                </template>

                 <template v-slot:[`item.dueDate`]="{ item }">
                    <span :class="'span-' + item.type ? item.type.toLowerCase() : ''">
                        {{ item ? formatDate(item.dueDate) : null }}
                    </span>
                </template>

                <template v-slot:[`item.type`]="{ item }">
                    <span :class="'span-' + item.type ? item.type.toLowerCase() : ''">
                        <v-icon class="mr-1">mdi-file-document-outline</v-icon>
                        <v-chip class="status-chip" small>
                            {{ item.type }}
                        </v-chip>
                    </span>
                </template>

                <template v-slot:[`item.nominalValue`]="{ item }">
                    <span :class="'span-' + item.type ? item.type.toLowerCase() : ''">
                        {{ item ? formatarDinheiro(item.nominalValue) : null }}
                    </span>
                </template>

                <template v-slot:footer>
                    <v-pagination
                        color="secondary"
                        v-model="page"
                        class="mt-3"
                        :length="followUpListResult.totalPages"
                        :total-visible="7"
                    ></v-pagination>
                </template>
            </v-data-table>

            <Loader
                v-if="followUpListRequestStatus === RequestStatusEnum.START"
                class="text-center mt-3"
            />
        </div>
    </v-main>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import MainFilter from '@/components/base/mainfilter/MainFilter';
import Filters from '../components/_FiltersLista';
import headers from './_headers';
import { formatarDinheiro } from '@/helpers/formatarDinheiro';
import { formatDate } from '@/helpers/data';
import { RequestStatusEnum } from '@/helpers/consts';
import Loader from '@/components/base/Loader';

export default {
    components: {
        MainFilter,
        Filters,
        Loader,
    },
    data() {
        return {
            showFilters: false,
            listTitulosResult: {
                totalPages: 3,
                items: [],
            },
            page: 1,
            headers,
            lastSearchFilters: {},
            filters: {
                page: 0,
                limit: 10,
            },
            RequestStatusEnum,
        };
    },
    computed: {
        ...mapGetters('gestao', {
            followUpListResult: 'followUpListResult',
            followUpListRequestStatus: 'followUpListRequestStatus',
            selectedProduct: 'selectedProduct',
        }),
    },
    methods: {
        ...mapActions('gestao', {
            fetchFollowUp: 'fetchFollowUp',
        }),
        filterInputChanged(value) {
            this.filters.nomeOuDoc = value;
            if (value.length >= 3 || value === '') {
                this.doSearch();
            }
        },
        toggleFilter() {
            this.showFilters = !this.showFilters;
        },
        searchListener(data) {
            const nomeOuDoc = this.filters.nomeOuDoc || '';
            this.filters = { ...data, page: 0, limit: 10 };
            this.filters.nomeOuDoc = nomeOuDoc;
            this.doSearch();
        },

        doSearch() {
            if (!this.filters.nomeOuDoc) {
                delete this.filters.nomeOuDoc;
            }
            this.lastSearchFilters = { ...this.filters };
            this.fetchFollowUp({
                idProduct: this.selectedProduct.id,
                status: 2,
                filters: { ...this.filters },
            });
        },
        formatarDinheiro,
        formatDate,
    },
    mounted() {
        this.doSearch();
    },
    watch: {
        page() {
            this.filters.page = this.page - 1;
            this.doSearch();
        },
    },
};
</script>
<style scoped lang="scss">
@import './_tableTitulos.scss';
</style>