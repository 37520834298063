<template>
    <v-dialog content-class="modal-default modal-default-top" persistent v-model="showModal">
        <header>
            {{ selected.name }}
            <v-icon role="button" title="Fechar" @click="closeModal" color="altPrimary">
                mdi-close-thick
            </v-icon>
        </header>

        <v-main>
            <section class="pb-16">
                <div class="mt-2 ml-4">
                    <span class="invoiceIndicator">Notas:</span>
                    <span class="numberInvoiceIndicator"> {{ select.length }}</span>
                </div>
                <v-row justify="space-between" class="ml-1 mr-6 mt-0">
                    <v-col md="2" sm="12" cols="12" lg="2">
                        <v-btn
                            depressed
                            class="white--text"
                            @click="save"
                            large
                            color="secondary"
                            :disabled="select.length === 0"
                        >
                            <span class="white--text text-none">Salvar</span>
                        </v-btn>
                    </v-col>
                    <v-col md="2" sm="12" cols="12" lg="2">
                        <MainFilter :hasInitialInput="false" @onClick="mostrarFiltro" />
                    </v-col>
                </v-row>

                <v-row v-show="filter" class="ml-1 mb-1">
                    <Filters @onApply="searchOriginacoesListener" />
                </v-row>

                <v-col lg="12" class="tableDivModal">
                    <template>
                        <v-data-table
                            :headers="headers"
                            :items="notas"
                            :show-select="selected.registry === '1'"
                            v-model="select"
                            hide-default-footer
                            :options.sync="pagination"
                            class="border-table"
                        >
                            <template v-slot:[`item.type`]="{ item }">
                                <v-chip class="status-chip">
                                    {{ item.type }}
                                </v-chip>
                            </template>
                            <template v-slot:footer>
                                <v-pagination
                                    color="secondary"
                                    v-model="pagination.page"
                                    :length="pagination.pages"
                                    :total-visible="7"
                                ></v-pagination>
                            </template>
                        </v-data-table>
                    </template>
                </v-col>
            </section>
        </v-main>
        <v-main class="container-footer">
            <Footer :notas="select" />
        </v-main>
    </v-dialog>
</template>

<script>
import MainFilter from '@/components/base/mainfilter/MainFilter';
import Filters from './_FiltersModal';

import Footer from './_footer';
import { headersModal } from './consts';

export default {
    props: {
        selected: {
            type: Object,
            default: null,
        },
        modal: {
            type: Boolean,
            default: false,
        },
        totalNotas: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        Footer,
        MainFilter,
        Filters,
    },
    data: () => ({
        showModal: false,
        page: 0,
        select: [],
        headers: headersModal,
        filter: false,
        notas: [],
        pagination: {
            descending: true,
            page: 1,
            itemsPerPage: 10,
        },
    }),
    methods: {
        closeModal() {
            this.$emit('onClose', this.selected);
            this.showModal = false;
        },
        save() {
            this.$emit('onSave', { notas: this.select, selected: this.selected });
            this.showModal = false;
        },
        mostrarFiltro() {
            this.filter = !this.filter;
        },
        searchOriginacoesListener(filters) {
            this.notas = this.selected.notas.filter(el => {
                let res = true;
                if (filters.number) {
                    res = res && el.number.includes(filters.number);
                }
                if (filters.supplier) {
                    res = res && el.supplier.toUpperCase().includes(filters.supplier.toUpperCase());
                }
                return res;
            });
        },
        startNotas() {
            const notas = this.totalNotas.filter(el => el.parentId === this.selected.id);
            this.pagination.pages = Math.ceil(this.notas.length / this.pagination.itemsPerPage);
            return notas;
        },
    },
    created() {
        
        this.showModal = this.modal;
        this.notas = this.selected.notas;
        this.select = this.startNotas();
    },
};
</script>

<style scoped>
.container-footer {
    min-width: 100%;
    position: fixed;
    bottom: 0.75rem;
    left: 0;
    z-index: 5;
}
</style>
