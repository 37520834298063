export default [
    'originacoesList',
    'statesList',
    'usersList',
    'segmentsList',
    'simulacaoList',
    'availabilityDate',
    'availabilityToday',
    'originationId',
    'selectedProduct',
    'exportOriginacao',
    'originacao',
    'type',
    'originacoesListTotalPages',
];
