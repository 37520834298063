export const pendingHeaders = [
    {
        text: 'Nome',
        sortable: false,
        value: 'name',
    },
    {
        text: 'CPF/CNPJ',
        sortable: false,
        value: 'cpfCnpj',
        align: 'center',
        width: 160,
    },
    {
        text: 'E-mail',
        sortable: false,
        value: 'email',
        align: 'center',
        width: 192,
    },
    {
        text: '',
        sortable: false,
        value: 'resend',
        align: 'center',
        width: 112,
    },
    {
        text: 'Status',
        sortable: false,
        value: 'active',
        align: 'center',
        width: 60,
    },
    {
        text: 'Data Cadastro',
        sortable: false,
        value: 'createdAt',
        align: 'center',
        width: 160,
    },

];
export const consultHeader = [
    {
        text: 'Nome',
        sortable: false,
        value: 'name',
    },
    {
        text: 'CPF/CNPJ',
        sortable: false,
        value: 'cpfCnpj',
        align: 'center',
        width: 160,
    },
    {
        text: 'E-mail',
        sortable: false,
        value: 'email',
        align: 'center',
        width: 192,
    },
    {
        text: 'Perfil',
        sortable: false,
        value: 'profile',
        align: 'center',
        width: 192,
    },
    {
        text: 'Status',
        sortable: false,
        value: 'active',
        align: 'center',
        width: 60,
    },
    {
        text: 'Data Cadastro',
        sortable: false,
        value: 'createdAt',
        align: 'center',
        width: 160,
    },
];
