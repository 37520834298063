<template>
    <v-app id="app">
        <router-view />
    </v-app>
</template>

<script>
export default {
    props: ['drawer'],
    name: 'App',
    watch: {
        $route: {
            handler(to) {
                const body = document.getElementsByTagName('body')[0];
                if (!to) {
                    body.classList.remove('admin');
                    return '';
                }

                if (to.path.indexOf('/admin/') >= 0) {
                    body.classList.add('admin');
                    return 'admin';
                }
                body.classList.remove('admin');
                return '';
            },
            immediate: true,
        },
    },
};
</script>


<style lang="scss">
@import '@/assets/scss/ButtonOverrides.scss';
@import '@/assets/scss/FormOverrides.scss';
@import '@/assets/scss/DefaultModal.scss';
@import '@/assets/scss/Status-Chip';
@import '@/assets/scss/utils';
.v-main__wrap .v-main__wrap > div {
    background: white;
}
.styleRouterView.v-main .v-main__wrap > div.pb-main-default {
    padding-bottom: 4rem !important;
}
html {
    height: 100%;
}
body {
    min-height: 100%;
    font-family: 'Roboto', sans-serif;

    &.admin {
        background-color: #efefef;
    }
}
.apexcharts-legend-text {
    text-indent: 0.5rem;
}
.apexcharts-legend-text-count {
    font-weight: lighter;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}
.v-application .elevation-0.flat-container,
.v-application .flat-container {
    border: 1px solid #dddddd;
}
</style>
