<template>
    <v-main>
        <v-row class="mb-3" justify="space-between">
            <v-col md="6" sm="12" cols="12" lg="6">
                <MainFilter @onInputChange="filterInputChanged" :hasExtraFilters="false" />
            </v-col>
            <v-col md="2" sm="12" cols="12" align="right">
                <v-btn
                    title="Cadastrar novo perfil"
                    depressed
                    @click="$router.push('/admin/cadastros/usuarios/novo')"
                    color="secondary"
                >
                    Cadastrar
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table
            :items="pendingUsersListResult.results"
            hide-default-footer
            class="elevation-0 pb-4 flat-container table-row-clickable"
            :headers="headers"
            @click:row="onClickRow"
        >
            <template v-slot:[`item.active`]="{ item }">
                <v-chip small :class="`status-chip ${getStatus(item.active).color}`">
                    {{ getStatus(item.active).label }}
                </v-chip>
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
                {{ formatarData(item.createdAt) }}
            </template>

            <template v-slot:[`item.resend`]="{ item }">
                <v-btn
                    color="secondary"
                    :disabled="askForNewTokenRequestStatus === RequestStatusEnum.START"
                    depressed
                    @click="resend(item, $event)"
                >
                    Reenviar link
                </v-btn>
            </template>

            <template slot="no-data">
                <div>Não há dados</div>
            </template>

            <template v-slot:footer>
                <v-pagination
                    color="secondary"
                    v-model="page"
                    class="mt-3"
                    :length="pendingUsersListResult.totalPages"
                    :total-visible="7"
                ></v-pagination>
            </template>
        </v-data-table>
        <Loader
            class="mt-6"
            align="center"
            v-if="pendingUsersListRequestStatus === RequestStatusEnum.START"
        />
    </v-main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { RequestStatusEnum, RegistersStatus, RegistersStatusConsult } from '@/helpers/consts';
import { pendingHeaders as headers } from './_headers';
import { formatarData } from '@/helpers/data';
import { notification } from '@/helpers/notification';
import Loader from '@/components/base/Loader';
import MainFilter from '@/components/base/mainfilter/MainFilter';

export default {
    components: {
        MainFilter,
        Loader,
    },
    data() {
        return {
            filters: {},
            page: 1,
            headers,
            RequestStatusEnum,
            RegistersStatus,
        };
    },
    methods: {
        ...mapActions('cadastros', {
            fetchPendingUsers: 'fetchPendingUsers',
            askForNewToken: 'askForNewToken',
        }),

        onClickRow(data) {
            this.$router.push({ path: `/admin/cadastros/usuarios/${data.userId}/editar` });
        },
        getStatus(key) {
            return RegistersStatusConsult[key ? 1 : 0];
        },
        async doSearch() {
            let filters = {
                ...this.filters,
                page: this.page,
                limit: 10,
            };
            const payload = {
                filters,
            };
            await this.fetchPendingUsers(payload);
        },
        filterInputChanged(value) {
            this.filters.nomeOuDoc = value;
            if (value.length >= 3 || value === '') {
                this.doSearch();
            }
        },
        formatarData,
        resend(item, $event) {
            $event.preventDefault();
            $event.stopPropagation();
            this.askForNewToken({ userId: item.userId });
        },
    },
    watch: {
        page() {
            this.doSearch();
        },
        pendingUsersListRequestStatus(newValue) {
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    this.$tc('cadastros.users.list.error', newValue.message)
                );
                return RequestStatusEnum.ERROR;
            }
        },
        askForNewTokenRequestStatus(newValue) {
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    this.$tc('cadastros.users.askForNewToken.error', newValue.message)
                );
                return RequestStatusEnum.ERROR;
            }
            if (newValue === RequestStatusEnum.SUCCESS) {
                notification(
                    this.$dialog.notify.success,
                    this.$tc('cadastros.users.askForNewToken.success')
                );
                return RequestStatusEnum.SUCCESS;
            }
        },
    },

    computed: {
        ...mapGetters('cadastros', {
            pendingUsersListResult: 'pendingUsersListResult',
            pendingUsersListRequestStatus: 'pendingUsersListRequestStatus',
            askForNewTokenRequestStatus: 'askForNewTokenRequestStatus',
        }),
    },
    async created() {
        await this.doSearch();
    },
};
</script>
