import { originationMappingKeys } from './mappings';
import { formatarDinheiro } from '@/helpers/formatarDinheiro';
import { formatarDataBarra } from '@/helpers/data';

export default rawData => {
    const item = Object.fromEntries(
        Object.keys(originationMappingKeys).map(key => [
            [originationMappingKeys[key]],
            rawData[key],
        ])
    );
    item.total = rawData.total_nota;
    item.selected = 0;
    item.notasTotal = rawData.notas.length;
    item.notas = rawData.notas.map(nota => _buildNotas(nota, rawData.id_cliente));
    return item;
};

const _buildNotas = (el, parentId) => {
    return {
        id: el.id,
        type: el.pedidoValor ? 'Pedido' : 'Nota',
        number: el.numero,
        issueDate: formatarDataBarra(el.emissao),
        dueDate: formatarDataBarra(el.vencimento),
        supplier: el.fornecedor,
        valueFormated: formatarDinheiro(el.valor),
        value: el.valor,
        parentId,
    };
};
