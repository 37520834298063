<template>
    <section>
        <apexchart
            v-if="fundWalletRequestStatus === RequestStatusEnum.SUCCESS"
            height="400px"
            :series="fundWalletSeries"
            :options="fundWalletOptions"
        ></apexchart>

        <Loader
            v-if="fundWalletRequestStatus === RequestStatusEnum.START"
            class="text-center mt-3"
        />

        <v-alert
            v-if="fundWalletRequestStatus.type === RequestStatusEnum.ERROR"
            align="left"
            justify="center"
            dense
            outlined
            type="error"
            class="mt-6 ml-3 mr-3"
        >
            {{ $tc('dashboards.error') }}
            <v-btn depressed color="secondary" class="ml-3" @click="load">{{
                $tc('defaults.form.reload')
            }}</v-btn>
        </v-alert>
    </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { RequestStatusEnum } from '@/helpers/consts';
import Loader from '@/components/base/Loader';
import { carteiraDoFundoOptions } from './_options';
import { capitalize } from '@/helpers/masks';

export default {
    components: {
        Loader,
    },
    mounted() {
        this.load();
    },
    methods: {
        ...mapActions('dashboards', {
            fetchFundWallet: 'fetchFundWallet',
        }),
        load() {
            this.fetchFundWallet({
                idProduct: this.selectedProduct.id,
                filters: {},
            });
        },
    },
    computed: {
        ...mapGetters('dashboards', {
            selectedProduct: 'selectedProduct',
            fundWallet: 'fundWallet',
            fundWalletRequestStatus: 'fundWalletRequestStatus',
        }),
        fundWalletSeries() {
            return [
                {
                    name: 'Carteira do Fundo',
                    type: 'area',
                    data: this.fundWallet.carteiraFundo,
                },
                {
                    name: 'Patrimônio Líquido (pl)',
                    type: 'line',
                    data: this.fundWallet.carteiraPatrimonio,
                },
            ];
        },
        fundWalletOptions() {
            const options = { ...this.options };
            options.xaxis.categories = this.fundWallet.categories.map(item => {
                const month = item[0].split(' ')[0];
                const year = item[0].split(' ')[1].split('-')[0];
                const today = new Date();
                today.setMonth(month - 1);
                const monthAsString = today
                    .toLocaleDateString('pt-BR', { month: 'short' })
                    .split('.')[0];
                return capitalize(monthAsString) + ' ' + year;
            });
            return options;
        },
    },
    data() {
        return {
            RequestStatusEnum,
            dialog: false,
            options: {
                ...carteiraDoFundoOptions,
            },
        };
    },
    watch: {
        selectedProduct() {
            this.load();
        },
    },
};
</script>