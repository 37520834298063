<template>
    <section>
        <apexchart
            v-if="limitsUsagePerUFRequestStatus === RequestStatusEnum.SUCCESS"
            height="350px"
            :series="topSeriesBuilt"
            :options="options.top"
        ></apexchart>
        <Loader
            v-if="limitsUsagePerUFRequestStatus === RequestStatusEnum.START"
            class="text-center mt-3"
        />

        <v-row align="center" justify="center" class="mt-3 mb-3">
            <v-btn
                v-if="limitsUsagePerUFRequestStatus === RequestStatusEnum.SUCCESS"
                @click="dialog = true"
                color="secondary"
                depressed
                outlined
            >
                {{ $tc('dashboards.limitsPerUF.detailed') }}
            </v-btn>
        </v-row>

        <v-alert
            v-if="limitsUsagePerUFRequestStatus.type === RequestStatusEnum.ERROR"
            align="left"
            justify="center"
            dense
            outlined
            type="error"
            class="mt-6 ml-3 mr-3"
        >
            {{ $tc('dashboards.error') }}
            <v-btn depressed color="secondary" class="ml-3" @click="load">
                {{ $tc('defaults.form.reload') }}
            </v-btn>
        </v-alert>

        <v-dialog content-class="modal-default" v-model="dialog">
            <header>
                {{ $tc('dashboards.limitsPerUF.title') }}
                <v-icon role="button" title="Fechar" @click="dialog = false" color="altPrimary">
                    mdi-close-thick
                </v-icon>
            </header>

            <v-main>
                <section class="mt-9" v-if="dialog">
                    <apexchart
                        height="480px"
                        :series="allSeriesBuilt"
                        :options="options.all"
                    ></apexchart>
                </section>
            </v-main>
        </v-dialog>
    </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { RequestStatusEnum } from '@/helpers/consts';
import Loader from '@/components/base/Loader';
import { limitsPerUFChartOptions } from './_options';
import { calculateWithDecimals } from '@/helpers/percentage';
const { abbreviateNumber } = require('@/helpers/masks');

limitsPerUFChartOptions.xaxis = { ...limitsPerUFChartOptions.xaxis };

export default {
    components: {
        Loader,
    },
    mounted() {
        this.load();
    },
    methods: {
        ...mapActions('dashboards', {
            fetchLimitsUsagePerUF: 'fetchLimitsUsagePerUF',
        }),
        load() {
            this.fetchLimitsUsagePerUF({ idProduct: this.selectedProduct.id, filters: {} });
        },
        resetSeries() {
            this.series = {
                top: { used: [], available: [] },
                all: { used: [], available: [] },
            };
            this.options.all.xaxis = {
                categories: [],
                labels: { ...limitsPerUFChartOptions.xaxis.labels },
            };
            this.options.top.xaxis = {
                categories: [],
                labels: { ...limitsPerUFChartOptions.xaxis.labels },
            };
        },
    },
    computed: {
        ...mapGetters('dashboards', {
            selectedProduct: 'selectedProduct',
            limitsUsagePerUF: 'limitsUsagePerUF',
            limitsUsagePerUFRequestStatus: 'limitsUsagePerUFRequestStatus',
        }),
        topSeriesBuilt() {
            return [
                {
                    name: 'Utilizado',
                    data: this.series.top.used,
                },
                {
                    name: 'Disponível',
                    data: this.series.top.available,
                },
            ];
        },
        allSeriesBuilt() {
            return [
                {
                    name: 'Utilizado',
                    data: this.series.all.used,
                },
                {
                    name: 'Disponível',
                    data: this.series.all.available,
                },
            ];
        },
    },
    data() {
        return {
            RequestStatusEnum,
            dialog: false,
            series: {
                top: { used: [], available: [] },
                all: { used: [], available: [] },
            },
            options: {
                top: { ...limitsPerUFChartOptions },
                all: { ...limitsPerUFChartOptions },
            },
            maxItems: 5,
        };
    },
    watch: {
        limitsUsagePerUF(newValue) {
            this.resetSeries();

            [...newValue]
                .sort((prev, next) => (prev.used > next.used ? -1 : 1))
                .forEach((item, index) => {
                    const perc = calculateWithDecimals(item.used, item.available + item.used, 0);
                    const percStr = `${item.uf} - ${abbreviateNumber(item.used)} (${perc}%)`;

                    this.series.all.available.push(item.available);
                    this.series.all.used.push(item.used);
                    this.options.all.xaxis.categories.push(percStr);

                    if (index < this.maxItems) {
                        this.series.top.available.push(item.available);
                        this.series.top.used.push(item.used);
                        this.options.top.xaxis.categories.push(percStr);
                    }
                });
        },
        selectedProduct() {
            this.load();
        },
        dialog(newValue) {
            if (newValue) {
                this.$gtag.event('open_limitsPerUf_dialog', {
                    event_category: 'dashboards',
                });
            }
        },
    },
};
</script>